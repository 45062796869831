import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Form, Navbar, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import PBPScheduleCard from './PBPScheduleCard'
import PBPView from './PBPView'
import AlertDismissable from '../misc/AlertDismissable'
import SpinStatus from '../misc/SpinStatus'
import TeamSelect from '../misc/TeamSelect'
import { teamOptions, getScheduleOptions, getPBPByGameID, putPBPByGameIDPlayID, postPBPOptions, deletePBPOptions } from '../lib/apiOptions'
import { weeks } from './options'
import useAPI from '../hooks/useAPI'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const seasons = ['2022', '2021', '2020', '2019']
const seasonTypes = ['Pre', 'Regular', 'Post']

const PlayByPlay = () => {
  const componentIsMounted = useRef(true)
  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])
  const { sendRequest: sendTeamRequest, status: teamLoading, error: teamError, data: Teams } = useAPI(
    teamOptions
  )
  const { sendRequest: sendGetPBPRequest, status: pbpLoading, error: pbpError, data: PBP } = useAPI(
    getPBPByGameID
  )
  const { sendRequest: sendEditPBPRequest, status: PBPEditing, error: PBPEditError } = useAPI(putPBPByGameIDPlayID)
  const { sendRequest: sendPBPPost, status: PBPPosting, error: PBPPostError } = useAPI(postPBPOptions)
  const { sendRequest: sendScheduleRequest, status: scheduleLoading, error: scheduleError, data: Schedule } = useAPI(getScheduleOptions)
  const { sendRequest: deletePlayRequest, status: deletePlayStatus, error: deletePlayError } = useAPI(deletePBPOptions)
  const [gameID, setGameID] = useState()
  const [gameIDForRefresh, setGameIDForRefresh] = useState()
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selectedWeek, setSelectedWeek] = useState(null)
  const [selectedSeason, setSelectedSeason] = useState(null)
  const [selectedSeasonType, setSelectedSeasonType] = useState(null)
  const [selectComplete, setSelectComplete] = useState(false)
  const [apiURL, setApiURL] = useState(`${getScheduleOptions.baseUrl}\\${selectedSeason}\\${selectedSeasonType}\\${selectedWeek}\\${selectedTeam}`)
  const [showPBPView, setShowPBPView] = useState(false)
  const [showScheduleCard, setShowScheduleCard] = useState(false)
  const [homeTeam, setHomeTeam] = useState()
  const [homeTeamID, setHomeTeamID] = useState()
  const [awayTeam, setAwayTeam] = useState()
  const [awayTeamID, setAwayTeamID] = useState()
  const [sendIt, setSendIt] = useState(false)
  // for confirm delete of play
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false)
  }
  const togglePBPView = () => setShowPBPView(showPBPView => !showPBPView)
  const toggleScheduleCard = () => setShowScheduleCard(showScheduleCard => !showScheduleCard)
  const toggleShowConfirmModal = () => setShowConfirmModal(showConfirmModal => !showConfirmModal)

  useEffect(() => {
    const controller = new AbortController()
    const getTeams = async (controller) => {
      sendTeamRequest(controller)
    }
    getTeams(controller)
    return () => {
      console.log('in cleanup fx for sendTeamRequest in PlayByPlay')
      controller?.abort()
    }
  }, [sendTeamRequest])

  useEffect(() => {
    if (selectedTeam !== null && selectedWeek !== null && selectedSeason !== null && selectedSeasonType !== null) {
      setApiURL(`${getScheduleOptions.baseUrl}\\${selectedSeason}\\${selectedSeasonType}\\${selectedWeek}\\${selectedTeam}`)
      setSelectComplete(true)
    }
  }, [selectedTeam, selectedWeek, selectedSeason, selectedSeasonType])


  const handleSubmit = (e) => {
    e.preventDefault()
    getScheduleOptions.url = apiURL
    setSendIt(true)

    //const controller = new AbortController()
    /*const getMatchups = async (controller) => {
      sendScheduleRequest(controller)
    }
    getMatchups(controller)*/
    //if (showPBPView) {togglePBPView()}
    //toggleScheduleCard()
  }

  useEffect(() => {
    if (sendIt) {
      console.log("I'm going to send it!")
      const controller = new AbortController()
      const getMatchups = async (controller) => {
        sendScheduleRequest(controller, componentIsMounted)
      }
      getMatchups(controller)
      return () => {
        console.log('executing cleanup in PlayByPlay')
        return () => controller.abort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendIt])

  useEffect(() => {
    setSendIt(false)
    if (scheduleLoading === 'completed' && scheduleError === null) {
      if (showPBPView) { togglePBPView() }
      toggleScheduleCard()
    }
    return () => {
      console.log('Goodbye')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleLoading, scheduleError])

  const handleSelectPBP = (e, aTeam, hTeam, aTeamID, hTeamID) => {
    setGameID(e)
    setAwayTeam(aTeam)
    setHomeTeam(hTeam)
    setHomeTeamID(hTeamID)
    setAwayTeamID(aTeamID)
    const controller = new AbortController()
    getPBPByGameID.url = `${getPBPByGameID.baseUrl}\\${e}`
    sendGetPBPRequest(controller)
    if (showScheduleCard) { toggleScheduleCard() }
    togglePBPView()
  }

  const refreshPlays = (gameID) => {
    const controller = new AbortController()
    getPBPByGameID.url = `${getPBPByGameID.baseUrl}\\${gameID}`
    sendGetPBPRequest(controller)
    return () => controller.abort()
  }

  const postPlay = (data) => {
    const controller = new AbortController()
    postPBPOptions.body = JSON.stringify(data)
    sendPBPPost(controller)
    setGameIDForRefresh(data.game_id)
  }
  const editPlay = (gameID, playID, data) => {
    const controller = new AbortController()
    putPBPByGameIDPlayID.url = `${putPBPByGameIDPlayID.baseUrl}\\${gameID}\\${playID}`
    putPBPByGameIDPlayID.body = JSON.stringify(data)
    sendEditPBPRequest(controller)
    setGameIDForRefresh(gameID)
  }
  // logic to delete play and show confirm module
  const [deletePlay, setDeletePlay] = useState({})
  const handleConfirmModal = () => {
    const controller = new AbortController()
    deletePBPOptions.url = `${deletePBPOptions.baseUrl}\\${deletePlay.Game_id}\\${deletePlay.Play_id}`
    deletePlayRequest(controller)
    toggleShowConfirmModal()
    setGameIDForRefresh(deletePlay.Game_id)
  }
  useEffect(() => {
    if (PBPPosting === 'completed' && !PBPPostError) {
      refreshPlays(gameIDForRefresh)
    }
    if (PBPEditing === 'completed' && !PBPEditError) {
      refreshPlays(gameIDForRefresh)
    }
    if (deletePlayStatus === 'completed' && !deletePlayError) {
      refreshPlays(gameIDForRefresh)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PBPPosting, PBPEditing, deletePlayStatus, gameIDForRefresh])
  const handleShowConfirmModal = (play) => {
    setDeletePlay(play)
    toggleShowConfirmModal()
    //setShowConfirmModal(true)
  }

  return (
    <Container>
      <Navbar bg='light' variant='light' className='mt-3' expand='lg'>
        <Navbar.Toggle aria-controls='player-navbar' />
        <Navbar.Collapse id='player-navbar-collapse' >
          <Form className='d-flex ms-3'>
            <Nav>
              <Nav navbarScroll onSelect={(e) => { setSelectedSeason(e) }}>
                <NavDropdown title='Season' id='season-dropdown'>
                  {seasons.map(season => (
                    <NavDropdown.Item
                      key={season}
                      eventKey={season}
                      onClick={() => setSelectedSeason(season)}
                    >
                      {season}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>
              <Nav onSelect={(e) => { setSelectedSeasonType(e) }}>
                <NavDropdown title='Season Type' id='season-dropdown'>
                  {seasonTypes.map(type => (
                    <NavDropdown.Item
                      key={type}
                      eventKey={type}
                      onClick={() => setSelectedSeasonType(type)}
                    >
                      {type}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>
              <Nav onSelect={(e) => { setSelectedTeam(e) }}>
                <NavDropdown title="Select Team" id='team-dropdown'>
                  {teamLoading === 'completed' && teamError === null && (
                    <TeamSelect Teams={Teams} />
                  )}
                </NavDropdown>
              </Nav>
              <Nav>
                <NavDropdown title="Select Week" id='team-dropdown' onSelect={(e) => { setSelectedWeek(e) }}>
                  {weeks.map(week => (
                    <NavDropdown.Item
                      key={week}
                      eventKey={week}
                    >
                      {week}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </Nav>

              <Nav>
                <Button
                  className='mx-2'
                  variant='secondary'
                  type='submit'
                  disabled={!selectComplete}
                  onClick={(e) => handleSubmit(e)}>Submit</Button>
              </Nav>
            </Nav>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      {pbpError !== null && (
        <AlertDismissable
          message={'Issue retrieving play by play data, please try again later...'}
          variant='danger'
        />
      )}
      {deletePlayStatus === 'pending' && (
        <SpinStatus message='submitting delete...' />
      )}

      {scheduleLoading === 'pending' && (
        <><div><Spinner animation='border' variant='primary' /><strong>&nbsp;&nbsp;Loading...</strong></div></>
      )}
      {scheduleLoading === 'completed' && showScheduleCard && (
        <PBPScheduleCard Schedule={Schedule} onSelect={handleSelectPBP} />
      )}
      {pbpLoading === 'pending' && (
        <><div><Spinner animation='border' variant='primary' /><strong>&nbsp;&nbsp;Loading...</strong></div></>
      )}
      {PBPPosting === 'completed' && PBPPostError === null && (
        <AlertDismissable message={'successfully added play'} variant='success' />
      )}
      {PBPPosting === 'completed' && PBPPostError !== null && (
        <AlertDismissable message={`Issue with submission: ${PBPPostError}`} variant='danger' />
      )}
      {PBPEditing === 'completed' && PBPEditError === null && (
        <AlertDismissable message={`successfully edited play`} variant='success' />
      )}
      {deletePlayStatus === 'completed' && deletePlayError === null && (
        <AlertDismissable message={'play has been deleted'} variant='success' />
      )}
      {showPBPView && pbpLoading === 'completed' && (
        <PBPView
          gameID={gameID}
          PlayByPlay={PBP}
          selectedWeek={selectedWeek}
          selectedSeason={selectedSeason}
          home={homeTeam}
          homeID={homeTeamID}
          away={awayTeam}
          awayID={awayTeamID}
          postPlay={postPlay}
          PBPPosting={PBPPosting}
          PBPPostError={PBPPostError}
          editPlay={editPlay}
          PBPEditing={PBPEditing}
          PBPEditError={PBPEditError}
          refreshPlays={refreshPlays}
          show={showConfirmModal}
          onHide={handleCloseConfirmModal}
          title={'Confirm Delete'}
          message={'Are you sure you want to delete this play?'}
          data={deletePlay}
          onSetConfirm={handleConfirmModal}
          onCloseModal={handleCloseConfirmModal}
          handleShowConfirmModal={handleShowConfirmModal}
          deletePlayStatus={deletePlayStatus}
          deletePlayError={deletePlayError}
        />
      )}
    </Container>
  )
}

export default withAuthenticationRequired(PlayByPlay, {
  onRedirection: () => <PlayByPlay />
})
