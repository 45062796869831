import React, { useContext, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavItem from 'react-bootstrap/NavItem'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import { useAuth0 } from '@auth0/auth0-react'
import ProfileContext from '../store/profile-context'


const MainNavigation = () => {
  const { updateUserEmail } = useContext(ProfileContext)

  let picURL = ''
  let userEmail = ''
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout
  } = useAuth0()
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    })
  if (isAuthenticated) {
    console.log(user.name)
    console.log(user)
    userEmail = user.email
    picURL = new URL(user.picture)
    picURL.searchParams.set('s', '35')
  }

  useEffect(() => {
    updateUserEmail(userEmail)
  }, [userEmail, updateUserEmail])
  return (

    <Container>
      <Navbar bg='dark' variant='dark' className='dflex justify-content-between p-2' expand='lg'>
        <Navbar.Brand>B.R.awk Data Administration</Navbar.Brand>
        {!isAuthenticated && (
          <Button
            id='loginButton'
            variant='outline-light'
            className='p-2'
            onClick={() => loginWithRedirect()}
          >Login
          </Button>
        )}
        {isAuthenticated && (
          <Nav>
            {picURL
              ? <NavItem><Image roundedCircle className='me-3' height="35" width="35" src={picURL} /></NavItem>
              : <Navbar.Text className='p-2'>{user.name}</Navbar.Text>}
            <NavItem>
              <Button
                id='logoutButton'
                variant='outline-light'
                className='p-2'
                onClick={() => logoutWithRedirect()}
              >Logout
              </Button>
            </NavItem>
          </Nav>
        )}
      </Navbar>
    </Container>

  )
}

export default MainNavigation