import React, { useEffect }  from 'react'
import ScheduleForm from './ScheduleForm'
import useAPI from '../hooks/useAPI'
import { postScheduleOptions, teamOptions } from '../lib/apiOptions'


const NewSchedule = (props) => {
  // TODO #2 handle teamError
  const { sendRequest: sendTeamRequest, status: teamLoading, error: teamError, data: TeamCodes } = useAPI(
    teamOptions
  )
  const { sendRequest, status, error } = useAPI(postScheduleOptions)

  const addMatchupHandler = (data) => {
    let controller = new AbortController()
    postScheduleOptions.body = JSON.stringify(data) 
    sendRequest(controller)
  }

  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      sendTeamRequest(controller)
    }
    getTeams()
    return () => controller?.abort()
  },[sendTeamRequest])

  useEffect(() => {
    if (status === 'completed' && error === null) {
      console.log('Edit was successfull perhaps I should update the matchup!')
      //lets try forcing a refresh of the current matchups
      props.onAddNewSchedule(props.scheduleAddIndex + 1)
      props.onAddMatchupFinished()

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[status, error])

  return(
    <>
      <div>
        {teamLoading === 'pending' && (
          <div className='d-flex align-items-center'>
            <strong>Loading...</strong>
            <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
          </div>
        )}
      </div>
      {teamLoading === 'completed' && (<ScheduleForm
        source={"New"}
        matchup={null} 
        loading={teamLoading}
        rawStatus={status}
        status={status === 'completed'}
        postError={error}
        teamError={teamError}
        teamNames={TeamCodes}
        handleMatchup={addMatchupHandler}
        onBackClick={props.onBackClick}
      />)}
    </>
  )

}

export default NewSchedule