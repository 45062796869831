import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { teamAtom } from '../store/Teams'
import { useAtom } from 'jotai'


const Team = (props) => {
  const [teamA, ] = useAtom(teamAtom)

  return (
    <>
      <Card bg='light' className='mt-3'>
        <Card.Header className='d-flex justify-content-between'>
          {/*props.teamData.Team_name*/}
          {teamA.Team_name}
          <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBackClick()}>Back</Button>
        </Card.Header>
        <Card.Body>
          <Form className='mt-3'>
          <Form.Group controlId='Team_name'>
              <Form.Label>Team name</Form.Label>
              <Form.Control
                name='Team_name'
                value={teamA.Team_name}
                readOnly
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId='formTeamAbbreviation'>
                  <Form.Label className='mt-3'>Team abbreviation</Form.Label>
                  <Form.Control
                    name='Team_abrv'
                    type='Team_abrv'
                    value={teamA.Team_abrv}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='Team_id'>
                  <Form.Label className='mt-3'>Team Id</Form.Label>
                  <Form.Control
                    name='Team_id'
                    type='number'
                    value={teamA.Team_id}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId='Team_city'>
                  <Form.Label className='mt-3'>Team City</Form.Label>
                  <Form.Control
                    name='Team_city'
                    value={teamA.Team_city}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='Team_state'>
                  <Form.Label className='mt-3'>Team State</Form.Label>
                  <Form.Control
                    name='Team_state'
                    value={teamA.Team_state}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId='conference'>
                  <Form.Label className='mt-3'>Conference</Form.Label>
                  <Form.Control
                    name='Team_conference'
                    value={teamA.Team_conference}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='division'>
                  <Form.Label className='mt-3'>Division</Form.Label>
                  <Form.Control
                    name='Team_division'
                    value={teamA.Team_division}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='justify-content-md-center mt-3'>
              <Col md='auto'>
                <Button 
                  variant='primary' 
                  size='lg'
                  onClick={() => props.onUpdateTeam()}
                >Edit Team
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default Team