import { penaltyDownTable } from "./penaltyDownTable"
import { penaltyYardsTable } from "./penaltyYardsTable"

export const getPenaltyNextDown = (lastPlay) => {
  const penaltyRuleDown = penaltyDownTable[lastPlay.Penalty_name] || ''
  const penaltyRuleYards = penaltyYardsTable[lastPlay.Penalty_name] || ''

  if (penaltyRuleDown === 'replay' && penaltyRuleYards !== '') {
    let first
    switch (lastPlay.Play_type) {
      case 'kickoff':
        first = true
        break;
      default:
        first = (lastPlay.Distance <= penaltyRuleYards)
        break;
    }
    return first ? 1 : lastPlay.Down
  }

  switch (penaltyRuleDown) {
    case 'lod':
      return lastPlay.Down + 1
    case 'first':
      return 1
    default:
      return
  }
}

export const checkChangeOfPossession = (lastPlay) => {
  let yardsGained = lastPlay.Run_yards > 0 ? lastPlay.Run_yards : 0
  yardsGained = lastPlay.Pass_yards > 0 ? lastPlay.Run_yards : 0
  const changeOfPossession = lastPlay.Play_type === 'punt'
    || lastPlay.Play_type === 'kickoff'
    || lastPlay.Turnover
    || (lastPlay.Down === 4 && lastPlay.Distance < yardsGained)
  return changeOfPossession
}

export const getNextDown = (lastPlay) => {
  let yardsGained
  if (lastPlay.Play_type === 'timeout') {
    return lastPlay.Down
  }
  if (lastPlay.Play_type === 'run') {
    yardsGained = lastPlay.Run_yards > 0 ? lastPlay.Run_yards : 0
  } else if (lastPlay.Play_type === 'pass') {
    yardsGained = lastPlay.Pass_yards > 0 ? lastPlay.Pass_yards : 0
  }
  if (yardsGained >= lastPlay.Distance) return 1
  if (yardsGained <= lastPlay.Distance && lastPlay.Down === 4) return 1
  return lastPlay.Down + 1
}

export const getPenaltyNextDistance = (lastPlay) => {
  if (lastPlay.Penalty_name === 'DPI') return 10
  const penaltyYards = penaltyYardsTable[lastPlay.Penalty_name] || ''
  return lastPlay.Distance + penaltyYards
}

export const getNextDistance = (lastPlay) => {
  if (lastPlay.Play_type === 'timeout') return lastPlay.Distance
  let yardsToGo
  if (lastPlay.Play_start_zone === lastPlay.Play_end_zone) {
    yardsToGo = lastPlay.Play_end_zone === lastPlay.Offense_team_name ?
      lastPlay.Distance - (lastPlay.Play_end_yardline - lastPlay.Play_start_yardline) :
      lastPlay.Distance - (lastPlay.Play_start_yardline - lastPlay.Play_end_yardline)
  } else {
    yardsToGo = lastPlay.Distance - (100 - (lastPlay.Play_end_yardline + lastPlay.Play_start_yardline))
  }
  /* const yardsToGo = lastPlay.Play_start_zone === lastPlay.Play_end_zone ?
    lastPlay.Distance - (lastPlay.Play_end_yardline - lastPlay.Play_start_yardline) :
    lastPlay.Distance - (100 - (lastPlay.Play_end_yardline + lastPlay.Play_start_yardline)) */
  console.log('here is yardsToGo: ', yardsToGo)
  return yardsToGo > 0 ? yardsToGo : 10
}

export const getNextStartYardline = (lastPlay, teamNameOptions) => {

  let newZone = Object.values(teamNameOptions).find((team) => team.value === lastPlay.Play_end_zone)

  let newStartYardline
  if (lastPlay.Play_type === 'kickoff') {
    newZone = lastPlay.Kickoff_returned_zone
    newStartYardline = lastPlay.Kickoff_returned_to
    return { newStartYardline, newZone }
  }

  if (lastPlay.Play_type === 'timeout') {
    newZone = { 'label': lastPlay.Play_start_zone, 'value': lastPlay.Play_start_zone }
    newStartYardline = lastPlay.Play_start_yardline
    return { newStartYardline, newZone }
  }

  newStartYardline = lastPlay.Play_end_yardline
  return { newStartYardline, newZone }
}

export const getNextStartYLZonePenalty = (lastPlay, teamNameOptions) => {
  let newZone
  let newStartYardline
  const penaltyYardsLookup = penaltyYardsTable[lastPlay.Penalty_name]
  const penaltyYards = penaltyYardsTable[lastPlay.Penalty_name] === 'spot foul' ? getSpotFoulYards(lastPlay) : penaltyYardsLookup
  const finalPenaltyYards = penaltyYards !== '' ? penaltyYards : lastPlay.Penalty_yards
  const yardsTotal = finalPenaltyYards < 0 ? lastPlay.Penalty_enforced_yardline + finalPenaltyYards :
    finalPenaltyYards + lastPlay.Penalty_enforced_yardline
  if (yardsTotal > 50) { newZone = Object.values(teamNameOptions).find((team) => team.value !== lastPlay.Play_end_zone) }
  newStartYardline = yardsTotal > 50 ? 50 - (yardsTotal - 50) : yardsTotal
  return { newStartYardline, newZone }
}

function getSpotFoulYards(lastPlay) {
  // eslint-disable-next-line default-case
  switch (lastPlay.Penalty_name) {
    case 'DPI':
      return lastPlay.Penalty_yards
    case 'IFHB':
      return -5 // not right...probably same as 'DPI' look at it later
  }
}

