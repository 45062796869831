import React, { useEffect, useContext, useState } from 'react'
import PlayerForm from './PlayerForm'
import useAPI from '../hooks/useAPI'
import ProfileContext from '../store/profile-context'
import { getTeamCodesOptions, editPlayerOptions } from '../lib/apiOptions'
import { Container } from 'react-bootstrap'

const EditPlayer = (props) => {
  
  const emlCtx = useContext(ProfileContext)
  const [playerName, setPlayerName] = useState()
  const {sendRequest: sendTeamRequest, status: teamLoading, error: teamError, data: TeamCodes} = useAPI(
    getTeamCodesOptions
  )
  const {sendRequest: sendPlayerUpdate, status: playerEditLoading, error: playerEditError} = useAPI(
    editPlayerOptions
  )

  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      sendTeamRequest(controller)
    }
    getTeams()
    return () => controller.abort()
  }, [sendTeamRequest])

  const editPlayerHandler = (data) => {
    setPlayerName(data.Full_name)
    let controller = new AbortController()
    editPlayerOptions.url = `${editPlayerOptions.baseUrl}\\${props.player.Id}`
    editPlayerOptions.body = JSON.stringify(data)
    sendPlayerUpdate(controller)
  }

  useEffect(() => {
    if (playerEditLoading === 'completed' && playerEditError === null) {
      props.onSuccessEdit(playerName)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[playerEditLoading, playerEditError])

  return(
    <>
      <>
      <Container>
        {teamLoading === 'pending' && (
          <div className='d-flex aign-items-center'>
            <strong>Loading...</strong>
            <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
          </div>
        )}
      </Container>
      {teamLoading === 'completed' && teamError === null && (
        <PlayerForm
          source = {'Edit'}
          player={props.player}
          teamData={TeamCodes}
          handlePlayer={editPlayerHandler}
          submitting={playerEditLoading}
          playerError={playerEditError}
          email={emlCtx.userEmail}
          onBackClick={props.onBackClick}
        />
      )}
    </>
    </>
  )

}

export default EditPlayer