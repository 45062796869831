/* eslint-disable default-case */
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
// import { Prompt } from 'react-router-dom' Prompt not supported in react-router-dom v6 yet
import { Alert, Button, Card, Col, Form, InputGroup, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Select, { components } from 'react-select'
import * as Yup from 'yup'
import useAPI from '../hooks/useAPI'
import { getPlayersByTeamBySeasonOptions } from '../lib/apiOptions'
import AlertDismissable from '../misc/AlertDismissable'
import StatusModal from '../misc/StatusModal'
import ProfileContext from '../store/profile-context'
import {
  penalties, personnelOptions, playDirections, playType, qbPosition,
  turnoverOptions, defensiveFronts, coverageOptions, incompletionTypes
} from './options'
import {
  getPenaltyNextDown, checkChangeOfPossession, getNextDown,
  getNextDistance, getPenaltyNextDistance, getNextStartYLZonePenalty,
  getNextStartYardline
} from './utilities'

const PBPForm = (props) => {
  const emlCtx = useContext(ProfileContext)
  const [value, setValue] = useState()
  // for PBPEditing status modal
  const [show, setShow] = useState(false)
  const handleCloseModal = () => setShow(false)
  const handleShowModal = () => setShow(true)

  // for PBPPosting status model
  const [showPosting, setShowPosting] = useState(false)
  const handleClosePostModal = () => setShowPosting(false)
  const handleShowPostModal = () => setShowPosting(true)

  // turn on status modal if props.PBPEditing is pending
  useEffect(() => {
    if (props.PBPEditing === 'pending') {
      handleShowModal()
    }
  }, [props.PBPEditing])

  // turn on status modal if props.PBPPosting is pending
  useEffect(() => {
    if (props.PBPPosting === 'pending') {
      handleShowPostModal()
    }
  }, [props.PBPPosting])

  const [offenseIndexChange, setOffenseIndexChange] = useState(0)

  // disable button state
  const [disableScoreButton, setDisableScoreButton] = useState(false)
  // when offense team change is made, clear out all offense and defense team affected fields
  useEffect(() => {
    if (offenseIndexChange > 0 && offensePlayerSelect.length > 0) {
      //setDefaultQB(offensePlayerSelect[0].label)
      formik.setFieldValue('quarterback', '')
      formik.setFieldValue('quarterback_id', '')
      formik.setFieldValue('passer', '')
      formik.setFieldValue('passer_id', '')
      formik.setFieldValue('receiver', '')
      formik.setFieldValue('receiver_id', '')
      formik.setFieldValue('tackled_by_solo', '')
      formik.setFieldValue('tackled_by_solo_id', '')
      formik.setFieldValue('assist_tackler_A', '')
      formik.setFieldValue('assist_tackler_A_id', '')
      formik.setFieldValue('assist_tackler_B', '')
      formik.setFieldValue('assist_tackler_B_id', '')
      formik.setFieldValue('kicker', '')
      formik.setFieldValue('kicker_id', '')
      formik.setFieldValue('kick_return_player', '')
      formik.setFieldValue('kick_return_player_id', '')
      formik.setFieldValue('punter', '')
      formik.setFieldValue('punter_id', '')
      formik.setFieldValue('punt_return_player', '')
      formik.setFieldValue('punt_return_player_id', '')
      formik.setFieldValue('field_goal_kicker', '')
      formik.setFieldValue('field_goal_kicker_id', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offenseIndexChange])

  const selectErrorStyle = (err) => {
    const style = {
      control: (base, state) => ({
        ...base,
        borderColor: err ? '#DC3545' : '#CED4DA',
        background: state.isDisabled ? '#E9ECEF' : '#FFFFFF'
      })
    }
    return style
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='18' height='18' fill='none' stroke='#dc3545'>
          <circle cx='6' cy='6' r='4.5' />
          <path strokeLinejoin='round' d='M5.8 3.6h.4L6 6.5z' />
          <circle cx='6' cy='8.2' r='.6' fill='#dc3545' stroke='none' /></svg>
      </components.DropdownIndicator>
    )
  }
  useEffect(() => {
    if (props.PBPEditing === 'completed' && props.PBPEditError === null) {
      props.closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.PBPEditing])

  const teamNameOptions = [
    { value: props.play.homeTeam, label: props.play.homeTeam },
    { value: props.play.awayTeam, label: props.play.awayTeam }
  ]
  const teamsByID = [
    { teamID: props.play.homeTeamID, teamName: props.play.homeTeam },
    { teamID: props.play.awayTeamID, teamName: props.play.awayTeam }
  ]
  let play = {}
  if (props.play.source === 'edit') {
    play = props.play.play
  }
  if (props.play.source === 'add') {
    play = props.play
  }

  let playListForCurrentSeries;
  // get plays in current series
  if (props.play.source === 'edit') {
    playListForCurrentSeries = props.playsBySeries.find(({ series }) => series === props.play.play.Series_id)
  }
  // when doing an 'add' don't pass play as a prop like we do edit so using last play in last series
  let lastPlay;
  if (props.play.source === 'add') {
    if (props.play.lastPlay === undefined) {
      //console.log('props.play.lastPlay: ', props.play.lastPlay)
      lastPlay = null
    } else {
      playListForCurrentSeries = props.playsBySeries.find(({ series }) => series === props.play.lastPlay.Series_id)
      // get last play in Series
      lastPlay = playListForCurrentSeries.plays.at(-1)
    }
  }
  // prepend zero to clock seconds for better visual display
  const prependZero = (number) => number <= 9 && props.play.source === 'edit' ? parseInt(`0${number}`) : number

  // when creating first play by play entry, maxSeriesID will be undefined
  // will cause uncontrolled to controlled component error so make 0
  const maxSeriesID = props.play.maxSeriesID === undefined ? 0 : props.play.maxSeriesID;
  const formik = useFormik({
    initialValues: {
      game_id: (play.Game_id ? play.Game_id : parseInt(props.play.gameID)),
      series_id: (play.Series_id ? play.Series_id : maxSeriesID),
      play_id: (play.Play_id ? play.Play_id : props.play.maxPlayID + 1),
      quarter: (play.Quarter ? play.Quarter : lastPlay.Quarter),
      offense_team_name: (play.Offense_team_name ? play.Offense_team_name : lastPlay.Offense_team_name ? lastPlay.Offense_team_name : ''),
      offense_team_id: (play.Offense_team_id ? play.Offense_team_id : 0),
      defense_team_name: (play.Defense_team_name ? play.Defense_team_name : ''),
      defense_team_id: (play.Defense_team_id ? play.Defense_team_id : 0),
      clock_minutes: (play.Clock_minutes ? play.Clock_minutes : 0),
      clock_seconds: (play.Clock_seconds ? prependZero(play.Clock_seconds) : 0),
      overtime_number: (play.Overtime_number ? play.Overtime_number : 0),
      play_type: (play.Play_type ? play.Play_type : ''),
      turnover: (play.Turnover ? play.Turnover : false),
      turnover_type: (play.Turnover_type ? play.Turnover_type : ''),
      turnover_player: (play.Turnover_player ? play.Turnover_player : ''),
      turnover_player_id: (play.Turnover_player_id ? play.Turnover_player_id : ''),
      turnover_recoverd_by: (play.Turnover_recovered_by ? play.Turnover_recovered_by : ''),
      turnover_recovered_by_id: (play.Turnover_recovered_by_id ? play.Turnover_recovered_by_id : ''),
      play_start_yardline: (props.play.source === 'add' ? calculateNextStartYardline(lastPlay, teamNameOptions).newStartYardline : play.Play_start_yardline),
      play_start_zone: (props.play.source === 'add' ? calculateNextStartYardline(lastPlay, teamNameOptions).newZone.value : play.Play_start_zone),
      play_end_yardline: (play.Play_end_yardline ? play.Play_end_yardline : 0),
      play_end_zone: (play.Play_end_zone ? play.Play_end_zone : ''),
      down: (play.Down ? play.Down : 0),
      distance: (props.play.source === 'add' ? calculateDistance(lastPlay) : play.Distance),
      quarterback: (play.Quarterback ? play.Quarterback : ''),
      quarterback_id: (play.Quarterback_id ? play.Quarterback_id : ''),
      passer: (play.Passer ? play.Passer : ''),
      passer_id: (play.Passer_id ? play.Passer_id : ''),
      runner: (play.Runner ? play.Runner : ''),
      runner_id: (play.Runner_id ? play.Runner_id : ''),
      receiver: (play.Receiver ? play.Receiver : ''),
      receiver_id: (play.Receiver_id ? play.Receiver_id : ''),
      pass_yards: (play.Pass_yards ? play.Pass_yards : 0),
      reception_caught_yardline: (play.Reception_caught_yardline ? play.Reception_caught_yardline : 0),
      reception_down_yardline: (play.Reception_down_yardline ? play.Reception_down_yardline : 0),
      yards_after_catch: (play.Yards_after_catch ? play.Yards_after_catch : 0),
      incompletion_type: (play.Incompletion_type ? play.Incompletion_type : ''),
      run_yards: (play.Run_yards ? play.Run_yards : 0),
      play_direction: (play.Play_direction ? play.Play_direction : ''),
      scoring_play: (play.Scoring_play ? play.Scoring_play : false),
      penalty: (play.Penalty ? play.Penalty : false),
      offsetting_penalties: (play.Offsetting_penalties ? play.Offsetting_penalties : false),
      penalty_declined: (play.Penalty_declined ? play.Penalty_declined : false),
      penalty_name: (play.Penalty_name ? play.Penalty_name : ''),
      penalty_yards: (play.Penalty_yards ? play.Penalty_yards : 0),
      penalty_team: (play.Penalty_team ? play.Penalty_team : ''),
      penalty_player: (play.Penalty_player ? play.Penalty_player : ''),
      penalty_player_id: (play.Penalty_player_id ? play.Penalty_player_id : ''),
      penalty_enforced_yardline: (play.Penalty_enforced_yardline ? play.Penalty_enforced_yardline : 0),
      penalty_enforced_zone: (play.Penalty_enforced_zone ? play.Penalty_enforced_zone : ''),
      penalty_team_B: (play.Peanalty_team_B ? play.Penalty_team_B : ''),
      penalty_player_B: (play.Penalty_player_B ? play.Penalty_player_B : ''),
      penalty_player_B_id: (play.Penalty_player_B_id ? play.Penalty_player_B_id : ''),
      penalty_name_B: (play.Penalty_name_B ? play.Penalty_name_B : ''),
      qb_position: (play.Qb_position ? play.Qb_position : ''),
      personnel: (play.Personnel ? play.Personnel : ''),
      yards_after_contact: (play.Yards_after_contact ? play.Yards_after_contact : 0),
      play_description: (play.Play_description ? play.Play_description : ''),
      defense_formation: (play.Defense_formation ? play.Defense_formation : ''),
      coverage: (play.Coverage ? play.Coverage : ''),
      assisted_tackle: (play.Assisted_tackle ? play.Assisted_tackle : false),
      sack: (play.Sack ? play.Sack : false),
      tackled_by_solo: (play.Tackled_by_solo ? play.Tackled_by_solo : ''),
      tackled_by_solo_id: (play.Tackled_by_solo_id ? play.Tackled_by_solo_id : ''),
      assist_tackler_A: (play.Assist_tackler_A ? play.Assist_tackler_A : ''),
      assist_tackler_A_id: (play.Assist_tackler_A_id ? play.Assist_tackler_A_id : ''),
      assist_tackler_B: (play.Assist_tackler_B ? play.Assist_tackler_B : ''),
      assist_tackler_B_id: (play.Assist_tackler_B_id ? play.Assist_tackler_B_id : ''),
      sacked_by: (play.Sacked_by ? play.Sacked_by : ''),
      sacked_by_id: (play.Sacked_by_id ? play.Sacked_by_id : ''),
      assisted_sack_by: (play.Assisted_sack_by ? play.Assisted_sack_by : ''),
      assisted_sack_by_id: (play.Assisted_sack_by_id ? play.Assisted_sack_by_id : ''),
      hurried_by: (play.Hurried_by ? play.Hurried_by : ''),
      hurried_by_id: (play.Hurried_by_id ? play.Hurried_by_id : ''),
      ob: (play.Ob ? play.Ob : false),
      ob_forced: (play.Ob_forced ? play.Ob_forced : false),
      kneel_slide: (play.Kneel_slide ? play.Kneel_slide : false),
      home_team_score: (play.Home_team_score ? play.Home_team_score : 0),
      away_team_score: (play.Away_team_score ? play.Away_team_score : 0),
      offensive_score_result: (play.Offensive_score_result ? play.Offensive_score_result : 0),
      defensive_score_result: (play.Defensive_score_result ? play.Defensive_score_result : 0),
      kicker: (play.Kicker ? play.Kicker : ''),
      kicker_id: (play.Kicker_id ? play.Kicker_id : ''),
      kick_return_player: (play.Kick_return_player ? play.Kick_return_player : ''),
      kick_return_player_id: (play.Kick_return_player_id ? play.Kick_return_player_id : ''),
      punt_return_player: play.Punt_return_player ? play.Punt_return_player : '',
      kickoff_from_yardline: (play.Kickoff_from_yardline ? play.Kickoff_from_yardline : 35),
      kickoff_yards: (play.Kickoff_yards ? play.Kickoff_yards : ''),
      kickoff_fair_catch: (play.Kickoff_fair_catch ? play.Kickoff_fair_catch : false),
      kickoff_touchback: (play.Kickoff_touchback ? play.Kickoff_touchback : false),
      kickoff_received_on: (play.Kickoff_received_on ? play.Kickoff_received_on : 0),
      kickoff_received_zone: (play.Kickoff_received_zone ? play.Kickoff_received_zone : ''),
      kickoff_returned_to: (play.Kickoff_returned_to ? play.Kickoff_returned_to : 0),
      kickoff_returned_zone: (play.Kickoff_returned_zone ? play.Kickoff_returned_zone : ''),
      kickoff_return_yards: (play.Kickoff_return_yards ? play.Kickoff_return_yards : 0),
      field_goal_kicker: (play.Field_goal_kicker ? play.Field_goal_kicker : ''),
      field_goal_kicker_id: (play.Field_goal_kicker_id ? play.Field_goal_kicker_id : ''),
      field_goal_yards: (play.Field_goal_yards ? play.Field_goal_yards : 0),
      pat_kicker: (play.Pat_kicker ? play.Pat_kicker : ''),
      pat_kicker_id: (play.Pat_kicker_id ? play.Pat_kicker_id : ''),
      pat_yards: (play.Pat_yards ? play.Pat_yards : 0),
      punter: (play.Punter ? play.Punter : ''),
      punter_id: (play.Punter_id ? play.Punter_id : ''),
      punt_return_player_id: (play.Punt_return_player_id ? play.Punt_return_player_id : ''),
      punt_from_yardline: (play.Punt_from_yardline ? play.Punt_from_yardline : ''),
      punt_received_on: (play.Punt_received_on ? play.Punt_received_on : 0),
      punt_returned_to: (play.Punt_returned_to ? play.Punt_returned_to : ''),
      punt_return_yards: (play.Punt_return_yards ? play.Punt_return_yards : 0),
      punt_yards: (play.Punt_yards ? play.Punt_yards : 0),
      punt_fair_catch: (play.Punt_fair_catch ? play.Punt_fair_catch : false),
      punt_touchback: (play.Punt_touchback ? play.Punt_touchback : false),
      timeout: (play.Timeout ? play.Timeout : false),
      timeout_team: (play.Timeout_team ? play.Timeout_team : ''),
      entered_by: (play.Entered_by ? play.Entered_by : emlCtx.userEmail)
    },
    validationSchema: Yup.object().shape({
      game_id: Yup.number().required('Required',),
      series_id: Yup.number().required('Required')
        .moreThan(0, 'Series must be greater than 0'),
      play_id: Yup.number().required('Required'),
      quarter: Yup.number().integer('must be integer between 1 and 4')
        .lessThan(5, 'for overtime use overtime number field').required('Required'),
      offense_team_name: Yup.string().required('Required'),
      offense_team_id: Yup.number().required('Required'),
      defense_team_name: Yup.string().required('Required'),
      defense_team_id: Yup.number().required('Required'),
      clock_minutes: Yup.number().integer('must be an integer')
        .lessThan(16, 'No more than 15 min per quarter').required('Required'),
      clock_seconds: Yup.number().integer().required('Required'),
      overtime_number: Yup.number().integer(),
      play_type: Yup.string().required('Required'),
      turnover: Yup.boolean(),
      turnover_type: Yup.string(),
      turnover_recovered_by: Yup.string('Must be a string'),
      //play_start_yardline: Yup.number().integer().moreThan(0, 'please select yardline the ball is starting'),
      play_start_yardline: Yup.number().when('play_type', {
        is: (play_type) => play_type === 'kickoff',
        then: Yup.number(),
        otherwise: Yup.number().integer().moreThan(0, 'select line of scrimmage')
      }),
      play_start_zone: Yup.string().when('play_type', {
        is: (play_type) => play_type === 'kickoff',
        then: Yup.string(),
        otherwise: Yup.string().required('please select team zone for line of scrimmage'),
      }),
      play_end_yardline: Yup.number().when(['play_type', 'scoring_play'], {
        is: (play_type, scoring_play) => play_type === 'kickoff' || play_type === 'fieldgoal'
          || play_type === 'point after touchdown' || play_type === 'timeout' || scoring_play,
        then: Yup.number(),
        otherwise: Yup.number().integer().moreThan(0, 'please select yardline for end of play'),
      }),
      //play_end_zone: Yup.string().required('please identify the team zone where ball reside at end of play'),
      play_end_zone: Yup.string().when('play_type', {
        is: (play_type) => play_type === 'kickoff' || play_type === 'timeout',
        then: Yup.string(),
        otherwise: Yup.string().required('please identify the team zone where play ended.')
      }),
      yards_after_contact: Yup.number().integer('must be an integer'),
      down: Yup.number().integer('downs are integers between 0 and 4 only').lessThan(5, 'not 5th down'),
      distance: Yup.number().integer('distance must be an integer').lessThan(100, 'you did not know a field is 100 yards?'),
      quarterback: Yup.string().when('play_type', {
        is: (play_type) => play_type === 'pass' || play_type === 'run' || play_type === '2pc-run' || play_type === '2pc-pass',
        then: Yup.string().required('Quarterback required fool!'),
        otherwise: Yup.string()
      }),
      quarterback_id: Yup.string(),
      passer: Yup.string(),
      runner: Yup.string(),
      receiver: Yup.string(),
      pass_yards: Yup.number().integer('must be an integer').lessThan(110, 'That is a long pass...too long!'),
      run_yards: Yup.number().integer('must be an integer').lessThan(110, 'That is a long run...too long!'),
      reception_caught_yardline: Yup.number().integer('must be an integer'),
      reception_down_yardline: Yup.number().integer('must be an integer'),
      yards_after_catch: Yup.number().integer('must be an integer'),
      play_direction: Yup.string(),
      scoring_play: Yup.boolean(),
      penalty: Yup.boolean(),
      penalty_yards: Yup.number().integer('must be an integer').lessThan(50, 'That is a long penalty...too long!'),
      penalty_team: Yup.string(''),
      penalty_player: Yup.string(''),
      penalty_enforced_yardline: Yup.number().lessThan(51, 'There is not yardline greater than the 50!').moreThan(-1, 'no negative yardlines!'),
      no_play: Yup.boolean(),
      qb_position: Yup.string(),
      personnel: Yup.number().integer('must be an integer'),
      coverage: Yup.string("must be a string"),
      play_description: Yup.string().required('Play description required'),
      home_team_score: Yup.number().integer('must be an integer').required('Need to know the score at each play'),
      away_team_score: Yup.number().integer('must be an integer').required('Need to know score at each play'),
      offensive_score_result: Yup.number().integer('must be an integer').when('scoring_play', {
        is: (scoring_play) => (scoring_play === true),
        then: Yup.number().integer('must be an integer').min(0, 'no negative numbers'),
        otherwise: Yup.number().integer(),
      }),
      defensive_score_result: Yup.number().integer('must be an integer').when('scoring_play', {
        is: (scoring_play) => (scoring_play === true),
        then: Yup.number().integer('must be an integer').min(0, 'no negative numbers'),
        otherwise: Yup.number().integer(),
      }),
      kickoff_yards: Yup.number('must be a number').integer('must be an integer').positive('must be a positive value'),
      kickoff_returned_to: Yup.number('must be a number').when(['play_type', 'kickoff_touchback'], {
        is: (play_type, kickoff_touchback) => play_type === 'kickoff' && !kickoff_touchback,
        then: Yup.number('must be a number').integer('must be an integer').positive('must be a positive value'),
        otherwise: Yup.number(),
      }),
      kickoff_received_on: Yup.number('must be numeric value!').when(['play_type', 'kickoff_touchback'], {
        is: (play_type, kickoff_touchback) => play_type === 'kickoff' && !kickoff_touchback,
        then: Yup.number('must be numerical').integer('must be an integer').lessThan(51, 'yardline out of range').moreThan(-10, 'yardline out of range'),
        otherise: Yup.number(),
      }),
      kickoff_touchback: Yup.boolean(),
      kickoff_return_yards: Yup.number().when('kickoff_touchback', {
        is: (kickoff_touchback) => kickoff_touchback === true,
        then: Yup.number('must be a number').integer('must an integer'),
        otherwise: Yup.number('must be a number').integer('must be an integer')
      }),
      punt_return_player: Yup.string(),
      tackled_by_solo: Yup.string().when(['assisted_tackle', 'sack', 'play_type', 'scoring_play', 'ob', 'kickoff_touchback', 'incompletion_type', 'kneel_slide'], {
        is: (assisted_tackle, sack, play_type, scoring_play, ob, kickoff_touchback, incompletion_type, kneel_slide) => (assisted_tackle === false &&
          sack === false && scoring_play === false && play_type !== 'point after touchdown' && play_type !== 'timeout' && ob === false &&
          kickoff_touchback === false && !incompletion_type && !kneel_slide),
        then: Yup.string().required('required when no assisted tackler'),
        otherwise: Yup.string(),
      }),
      assist_tackler_A: Yup.string().when('assisted_tackle', {
        is: (assisted_tackle) => assisted_tackle === true,
        then: Yup.string().required('please pick tackler'),
        otherwise: Yup.string(),
      }),
      assist_tackler_B: Yup.string().when('assisted_tackle', {
        is: (assisted_tackle) => assisted_tackle === true,
        then: Yup.string().required('please pick tackler'),
        otherwise: Yup.string(),
      }),
      sacked_by: Yup.string().when('sack', {
        is: (sack) => sack === true,
        then: Yup.string().required('please select the sacker!'),
        otherwise: Yup.string()
      }),
      entered_by: Yup.string()
    }),
    onSubmit: values => {
      //setValue(null)
      //formik.resetForm()
      console.log('here are submitted values: ', values)
      if (props.play.source === 'edit') {
        props.handleEditPlay(values.game_id, values.play_id, values)
        //props.closeModal()
      }
      if (props.play.source === 'add') {
        props.handlePostPlay(values)
        props.closeModal()
      }
      ////setIsBlocking(false)
    },
    validateOnChange: false,
    validateOnBlur: false

  })
  // setting state for enabling selects based on their dependencies on other inputs
  const [offenseTeam, setOffenseTeam] = useState(formik.values.offense_team_name)
  const [defenseTeam, setDefenseTeam] = useState(formik.values.defense_team_name)

  //const [defaultQB, setDefaultQB] = useState(formik.values.quarterback)
  const [disableOffensePlayer, setDisableOffensePlayer] = useState(true)
  const [penaltyPlayerSelect, setPenaltyPlayerSelect] = useState([])

  // clear values based on playtype e.g. no pass data if play type is kickoff and visa versa
  useEffect(() => {
    if (props.play.source === 'add') {
      switch (formik.values.play_type) {
        case "kickoff":
          formik.setFieldValue('run_yards', 0)
          formik.setFieldValue('runner', '')
          formik.setFieldValue('runner_id', '')
          formik.setFieldValue('yards_after_contact', 0)
          formik.setFieldValue('down', 0)
          formik.setFieldValue('distance', 0)
          formik.setFieldValue('play_start_yardline', 0)
          break
        case "run":
          formik.setFieldValue('kicker', '')
          formik.setFieldValue('kicker_id', '')
          formik.setFieldValue('kick_return_player', '')
          formik.setFieldValue('kick_return_player_id', '')
          formik.setFieldValue('kickoff_from_yardline', 0)
          formik.setFieldValue('punter', '')
          formik.setFieldValue('punter_id', '')
          formik.setFieldValue('punt_return_player', '')
          formik.setFieldValue('punt_return_player_id', '')
          formik.setFieldValue('down', calculateNextDown(lastPlay))
          break
        case "punt":
          formik.setFieldValue('down', calculateNextDown(lastPlay))
          break
        case "pass":
          formik.setFieldValue('down', calculateNextDown(lastPlay))
          break
        case "fieldgoal":
          formik.setFieldValue('down', calculateNextDown(lastPlay))
          break
        case "2pc-run":
          formik.setFieldValue('down', 0)
          break
        case "2pc-pass":
          formik.setFieldValue('down', 0)
          break
        case "point after touchdown":
          formik.setFieldValue('down', 0)
          formik.setFieldValue('play_start_yardline', 15)
          formik.setFieldValue('distance', 15)
          formik.setFieldValue('pat_yards', 33)
          break
        case "timeout":
          formik.setFieldValue('down', calculateNextDown(lastPlay))
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ([formik.values.play_type, props.source]))

  useEffect(() => {
    if (formik.values.assisted_tackle) {
      formik.setFieldValue('tackled_by_solo', '')
      formik.setFieldValue('tackled_by_solo_id', '')
      formik.setFieldError('tackled_by_solo', '')
      formik.setFieldValue('sacked_by', '')
      formik.setFieldValue('sacked_by_id', '')
      formik.setFieldError('sacked_by', '')
      formik.setFieldValue('assisted_sack_by', '')
      formik.setFieldValue('assisted_sack_by_id', '')
    }
    if (!formik.values.assisted_tackle) {
      formik.setFieldValue('assist_tackler_A', '')
      formik.setFieldValue('assist_tackler_A_id', '')
      formik.setFieldError('assist_tackler_A', '')
      formik.setFieldValue('assist_tackler_B', '')
      formik.setFieldValue('assist_tackler_B_id', '')
      formik.setFieldError('assist_tackler_B', '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.assisted_tackle])

  useEffect(() => {
    if (formik.values.sack) {
      formik.setFieldValue('tackled_by_solo', '')
      formik.setFieldValue('tackled_by_solo_id', '')
      formik.setFieldError('tackled_by_solo', '')
      formik.setFieldValue('assist_tackler_A', '')
      formik.setFieldValue('assist_tackler_A_id', '')
      formik.setFieldError('assist_tackler_A', '')
      formik.setFieldValue('assist_tackler_B', '')
      formik.setFieldValue('assist_tackler_B_id', '')
      formik.setFieldError('assist_tackler_B', '')
      formik.setFieldValue('hurried_by', '')
      formik.setFieldValue('hurried_by_id', '')
      formik.setFieldError('hurried_by', '')
    }
    if (!formik.values.sack) {
      formik.setFieldValue('sacked_by', '')
      formik.setFieldValue('sacked_by_id', '')
      formik.setFieldError('sacked_by', '')
      formik.setFieldValue('assisted_sack_by', '')
      formik.setFieldValue('assisted_sack_by_id', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.sack])

  // when kickoff_touchback is true, clear kickoff_received_on and kickoff_returned to
  useEffect(() => {
    if (formik.values.kickoff_touchback) {
      formik.setFieldValue('kickoff_received_on', 0)
      formik.setFieldValue('kickoff_returned_to', 25)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.kickoff_touchback])

  // when kickoff_fair_catch is true, clear kickoff returned to
  useEffect(() => {
    if (formik.values.kickoff_fair_catch) {
      formik.setFieldValue('kickoff_returned_to', formik.values.kickoff_received_on)
      formik.setFieldValue('kickoff_returned_zone', formik.values.kickoff_received_zone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.kickoff_fair_catch])

  // auto populate team ids based off selection of team_name
  useEffect(() => {
    if (formik.values.offense_team_name !== '') {
      let foundIndex = -1
      for (let i = 0; i < teamsByID.length; i++) {
        if (teamsByID[i].teamName === formik.values.offense_team_name) {
          foundIndex = i
          break
        }
      }
      formik.setFieldValue('offense_team_id', teamsByID[foundIndex].teamID)
      formik.setFieldValue('defense_team_name', defenseTeam)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.offense_team_name])

  // clear out penalty data when penalty checkbox = 'false'
  useEffect(() => {
    if (!formik.values.penalty) {
      formik.setFieldValue('penalty_name', '')
      formik.setFieldValue('penalty_yards', 0)
      formik.setFieldValue('penalty_team', '')
      formik.setFieldValue('penalty_player', '')
      formik.setFieldValue('penalty_player_id', '')
      formik.setFieldValue('penalty_player_B', '')
      formik.setFieldValue('penalty_player_B_id', '')
      formik.setFieldValue('penalty_name_B', '')
      formik.setFieldValue('offsetting_penalties', false)
      formik.setFieldValue('penalty_declined', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.penalty])

  // quarterback can't be populated on kickoff
  useEffect(() => {
    if (formik.values.play_type === 'kickoff') {
      formik.setFieldValue('quarterback', '')
      formik.setFieldValue('quarterback_id', '')
    }
    if (formik.values.play_type === 'pass') {
      formik.setFieldValue('runner', '')
      formik.setFieldValue('runner_id', '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.play_type])

  useEffect(() => {
    if (formik.values.quarterback) {
      formik.setFieldValue('passer', formik.values.quarterback)
      idLookup(formik.values.quarterback, allTeamPlayers, 'passer_id')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.quarterback])

  // Calculate punt yards distance from line of scrimmage to where punt was caught 
  // or went out of bounds
  useEffect(() => {
    let puntYards = 0
    if (formik.values.play_type === 'punt' && (formik.values.play_start_zone !== '' && formik.values.play_end_zone !== '')) {
      if (formik.values.play_start_zone === formik.values.play_end_zone) {
        puntYards = formik.values.punt_received_on ? formik.values.punt_received_on - formik.values.play_start_yardline : formik.values.play_end_yardline - formik.values.play_start_yardline;
        formik.setFieldValue('punt_yards', puntYards)
      } else if (formik.values.play_start_zone !== formik.values.play_end_zone) {
        puntYards = formik.values.punt_received_on ? 100 - (formik.values.play_start_yardline + formik.values.punt_received_on) : 100 - (formik.values.play_start_yardline + formik.values.play_end_yardline);
        formik.setFieldValue('punt_yards', puntYards)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.play_type, formik.values.play_start_yardline, formik.values.play_end_yardline,
  formik.values.punt_received_on, formik.values.play_start_zone, formik.values.play_end_zone])

  // Calculate kickoff Return Yards 
  useEffect(() => {
    if (formik.values.play_type === 'kickoff' && (formik.values.kickoff_received_zone !== '' && formik.values.kickoff_returned_zone !== '')) {
      if (formik.values.kickoff_received_zone === formik.values.kickoff_returned_zone) {
        const kickoffReturnYards = formik.values.kickoff_returned_to - formik.values.kickoff_received_on
        formik.setFieldValue('kickoff_return_yards', kickoffReturnYards)
      } else if (formik.values.kickoff_received_zone !== formik.values.kickoff_returned_zone) {
        const kickoffReturnYards = 100 - (formik.values.kickoff_returned_to + formik.values.kickoff_received_on)
        formik.setFieldValue('kickoff_return_yards', kickoffReturnYards)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.play_type, formik.values.kickoff_received_zone, formik.values.kickoff_received_on,
  formik.values.kickoff_returned_zone, formik.values.kickoff_returned_to])

  // auto populate defense_id based on defense_team_name selection
  useEffect(() => {
    if (formik.values.defense_team_name !== '') {
      let foundIndex = -1
      for (let i = 0; i < teamsByID.length; i++) {
        if (teamsByID[i].teamName === formik.values.defense_team_name) {
          foundIndex = i
          break
        }
      }
      formik.setFieldValue('defense_team_id', teamsByID[foundIndex].teamID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.defense_team_name])

  // do not allow for both score result fields to be greater than zero
  // i.e. both teams can't score on the same play.
  useEffect(() => {
    if (formik.values.offensive_score_result > 0) {
      formik.setFieldValue('offensive_score_result', 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.defensive_score_result])

  // do not allow for both score result fields to be greater than zero
  // i.e. both teams can't score on the same play.
  useEffect(() => {
    if (formik.values.defensive_score_result > 0) {
      formik.setFieldValue('defensive_score_result', 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.offensive_score_result])

  function calculateDistance(lastPlay) {
    if (props.play.source !== 'add' || lastPlay === null) return

    if (checkChangeOfPossession(lastPlay)) return 10

    if (lastPlay.Penalty) return getPenaltyNextDistance(lastPlay)

    return getNextDistance(lastPlay)
  }

  // calculate next down - rules to determine next down
  const calculateNextDown = (lastPlay) => {
    if (props.play.source !== 'add' || lastPlay === null) return
    if (lastPlay.Penalty) return getPenaltyNextDown(lastPlay)
    if (checkChangeOfPossession(lastPlay)) return 1
    return getNextDown(lastPlay)
  }

  // calculate next starting yardline
  function calculateNextStartYardline(lastPlay, teamNameOptions) {
    if (props.play.source !== 'add' || lastPlay === null) return
    if (lastPlay.Penalty) {
      let { newStartYardline, newZone } = getNextStartYLZonePenalty(lastPlay, teamNameOptions)
      return { newStartYardline, newZone }
    }
    let { newStartYardline, newZone } = getNextStartYardline(lastPlay, teamNameOptions)
    return { newStartYardline, newZone }
  }

  const { sendRequest: sendHomeTeamSearch, status: homeTeamPlayersLoading, error: homeTeamPlayerError, data: homeTeamPlayers } = useAPI(
    getPlayersByTeamBySeasonOptions
  )
  const { sendRequest: sendAwayTeamSearch, status: awayTeamPlayersLoading, error: awayTeamPlayerError, data: awayTeamPlayers } = useAPI(
    getPlayersByTeamBySeasonOptions
  )


  // Home Team Players
  useEffect(() => {
    const apiURL = `${getPlayersByTeamBySeasonOptions.baseUrl}\\${props.play.homeTeamID}\\${props.selectedSeason}`
    getPlayersByTeamBySeasonOptions.url = apiURL
    const controller = new AbortController()
    const getTeamPlayers = async (controller) => {
      sendHomeTeamSearch(controller)

    }
    getTeamPlayers(controller)
    return () => {
      controller.abort(controller)
      //console.log('executed clean-up function 0')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.play.homeTeamID, sendHomeTeamSearch])

  // Away Team Players
  useEffect(() => {
    const controller = new AbortController()
    if (homeTeamPlayersLoading === 'completed') {
      const apiURL = `${getPlayersByTeamBySeasonOptions.baseUrl}\\${props.play.awayTeamID}\\${props.selectedSeason}`
      getPlayersByTeamBySeasonOptions.url = apiURL

      const getTeamPlayers = async (controller) => {
        sendAwayTeamSearch(controller)
      }
      getTeamPlayers(controller)
    }
    return () => {
      controller.abort()
      //console.log('executed clean-up function 1')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.play.awayTeamID, sendAwayTeamSearch, homeTeamPlayersLoading])

  const allTeamPlayers = []
  const homeTeamPlayerOptions = []
  const awayTeamPlayerOptions = []
  if (awayTeamPlayersLoading === 'completed' && homeTeamPlayersLoading === 'completed') {

    const holdAwayPlayers = { teamID: props.play.awayTeamID, players: awayTeamPlayers }
    const holdHomePlayers = { teamID: props.play.homeTeamID, players: homeTeamPlayers }
    allTeamPlayers.push(holdAwayPlayers)
    allTeamPlayers.push(holdHomePlayers)

    for (const key in homeTeamPlayers) {
      homeTeamPlayerOptions.push({ value: homeTeamPlayers[key].Full_name, label: homeTeamPlayers[key].Full_name })
    }
    for (const aKey in awayTeamPlayers) {
      awayTeamPlayerOptions.push({ value: awayTeamPlayers[aKey].Full_name, label: awayTeamPlayers[aKey].Full_name })
    }
  }

  //const [penaltyPlayerSelectB, setPenaltyPlayerSelectB] = useState([])
  const toggleQBSelect = () => setDisableOffensePlayer(disableOffensePlayer => !disableOffensePlayer)

  const handleChangeSelect = (value, name) => {
    if (value !== null) {
      formik.setFieldValue(name.name, value.value)
      if (name.name === 'offense_team_name' && value.value === props.play.homeTeam) {
        setOffenseTeam(props.play.homeTeam)
        setDefenseTeam(props.play.awayTeam)
      } if (name.name === 'offense_team_name' && value.value === props.play.awayTeam) {
        setOffenseTeam(props.play.awayTeam)
        setDefenseTeam(props.play.homeTeam)
      }
      if (name.name === 'offense_team_name') {
        setOffenseIndexChange(offenseIndexChange + 1)
      }
      if (disableOffensePlayer) {
        toggleQBSelect()
      }
    }
  }

  const handleSetPenaltyPlayers = (team) => {
    if (team === props.play.homeTeam) {
      setPenaltyPlayerSelect(homeTeamPlayerOptions)
      formik.setFieldValue('penalty_player', '')
      formik.setFieldValue('penalty_player_id', '')
    } else if (team === props.play.awayTeam) {
      setPenaltyPlayerSelect(awayTeamPlayerOptions)
      formik.setFieldValue('penalty_player', '')
      formik.setFieldValue('penalty_player_id', '')
    }
  }

  let offensePlayerSelect = null
  let defensePlayerSelect = null

  if (offenseTeam === props.play.homeTeam) {
    offensePlayerSelect = homeTeamPlayerOptions
    defensePlayerSelect = awayTeamPlayerOptions
  } else if (offenseTeam === props.play.awayTeam) {
    offensePlayerSelect = awayTeamPlayerOptions
    defensePlayerSelect = homeTeamPlayerOptions
  } else {
    offensePlayerSelect = []
    defensePlayerSelect = []
  }

  const handleAddOrEdit = (property, message) => {
    if (props.play.source === 'edit') {
      return property !== "" ? property : message;
    }
    if (props.play.source === 'add') {
      return property !== "" ? property : message;
    }
  }

  const idLookup = (player, allTeamPlayers, field) => {
    let playerID = null
    for (let i = 0; i < allTeamPlayers.length; i++) {
      if (allTeamPlayers[i] !== null && allTeamPlayers[i].players !== null) {
        for (let j = 0; j < allTeamPlayers[i].players.length; j++) {
          if (allTeamPlayers[i].players[j].Full_name === player) {
            playerID = allTeamPlayers[i].players[j].Id
            formik.setFieldValue(field, playerID)
          }
        }
      }
    }
  }

  // function to calculate score
  const calculateScore = () => {
    let newScore = 0
    if (formik.values.defensive_score_result > 0) {
      if (formik.values.defense_team_name === props.play.homeTeam) {
        newScore = formik.values.home_team_score + formik.values.defensive_score_result
        formik.setFieldValue('home_team_score', newScore)
        formik.setFieldError('defensive_score_result', '')
      } else {
        newScore = formik.values.away_team_score + formik.values.defensive_score_result
        formik.setFieldValue('away_team_score', newScore)
        formik.setFieldError('defensive_score_result', '')
      }
    } else if (formik.values.defensive_score_result < 0) {
      formik.setFieldError('defensive_score_result', 'invalid value')
    } else if (formik.values.defensive_score_result === 0) {
      formik.setFieldError('defensive_score_result', '')
    }
    if (formik.values.offensive_score_result > 0) {
      if (formik.values.offense_team_name === props.play.homeTeam) {
        newScore = formik.values.home_team_score + formik.values.offensive_score_result
        formik.setFieldValue('home_team_score', newScore)
        formik.setFieldError('offensive_score_result', '')
      } else {
        newScore = formik.values.away_team_score + formik.values.offensive_score_result
        formik.setFieldValue('away_team_score', newScore)
        formik.setFieldError('offensive_score_result', '')
      }
    } else if (formik.values.offensive_score_result < 0) {
      formik.setFieldError('offensive_score_result', 'invalid value')
    } else if (formik.values.offensive_score_result === 0) {
      formik.setFieldError('offensive_score_result', '')
    }
  }

  return (
    <>
      <StatusModal
        show={show}
        onHide={handleCloseModal}
        status={props.PBPEditing}
        title={'Submitting Update...'}
      />
      <StatusModal
        show={showPosting}
        onHide={handleClosePostModal}
        status={props.PBPPosting}
        title={'Submitting New Play...'}
      />

      {homeTeamPlayerError !== null && (
        <AlertDismissable
          message={'issue retrieving home team data. Please try again later...'}
          variant='danger'
        />
      )}
      {awayTeamPlayerError !== null && (
        <AlertDismissable
          message={'issue retrieving away team data. Please try again later...'}
          variant='danger'
        />
      )}
      <Card className='mt-3'>
        <Card.Header as='h6'>Play ID: {formik.values.play_id}</Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            {/******************* NEW ROW *****************/}
            <Card.Subtitle as={Row}
              className='pb-1 ps-3 mt-1 fw-bold text-white bg-secondary rounded-top'>
              Play Metadata
            </Card.Subtitle>
            <Row className='border border-secondary rounded-bottom'>
              <Row className='mb-3 pt-2'>
                <Col md={4}>
                  <Form.Group controlId='EnteredBy'>
                    <Form.Label>Entered By</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='entered_by'
                        disabled
                        type='email'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.entered_by}
                        isInvalid={formik.errors.entered_by}
                        placeholder='Entered By'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.entered_by}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='GameID'>
                    <Form.Label>Game ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='game_id'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.game_id}
                        readOnly={true}
                        isInvalid={formik.errors.game_id}
                        placeholder='Game ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.game_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='SeriesID'>
                    <Form.Label>Series ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='series_id'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.series_id}
                        isInvalid={formik.errors.series_id}
                        placeholder='Series ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.series_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='PlayID'>
                    <Form.Label>Play ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='play_id'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.play_id}
                        isInvalid={formik.errors.play_id}
                        placeholder='Play ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.play_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='PlayType'>
                    <Form.Label>Play Type</Form.Label>
                    <Select
                      name='play_type'
                      isSearchable
                      isClearable
                      components={formik.errors.play_type ? { DropdownIndicator } : null}
                      styles={selectErrorStyle(formik.errors.play_type)}
                      placeholder={handleAddOrEdit(formik.values.play_type, 'Select Play Type')}
                      options={playType}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'play_type', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError("play_type", "")
                        } else {
                          formik.setFieldValue('play_type', '')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('play_type')}
                    />


                    {formik.touched.play_type && formik.errors.play_type
                      ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>{formik.errors.play_type}</div>) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='Quarter'>
                    <Form.Label>Quarter</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='quarter'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.quarter}
                        isInvalid={formik.errors.quarter}
                        placeholder='Quarter'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.quarter}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='Down'>
                    <Form.Label>Down</Form.Label>
                    {props.play.source === 'edit' && (
                      <InputGroup hasValidation>
                        <Form.Control
                          name='down'
                          type='number'
                          disabled={formik.values.play_type === 'kickoff' || '2pc-run' || '2pc-pass'}
                          onChange={e => {
                            formik.handleChange(e)
                            ////setIsBlocking(e.target.value.length > 0)
                          }}
                          value={formik.values.down}
                          isInvalid={formik.errors.down}
                          placeholder={handleAddOrEdit(formik.values.down, 'down')}
                        />
                        <Form.Control.Feedback type='invalid'>{formik.errors.down}</Form.Control.Feedback>
                      </InputGroup>
                    )}
                    {props.play.source === 'add' && (
                      <InputGroup hasValidation>
                        <Form.Control
                          name='down'
                          type='number'
                          disabled={formik.values.play_type === 'kickoff' ||
                            formik.values.play_type === '2pc-run' ||
                            formik.values.play_type === '2pc-pass' || formik.values.play_type === ''}
                          onChange={e => {
                            formik.handleChange(e)
                            ////setIsBlocking(e.target.value.length > 0)
                          }}
                          value={formik.values.down}
                          isInvalid={formik.errors.down}
                          placeholder='Down'
                        />
                        <Form.Control.Feedback type='invalid'>{formik.errors.down}</Form.Control.Feedback>
                      </InputGroup>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='Distance'>
                    <Form.Label>Distance</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='distance'
                        type='number'
                        disabled={formik.values.play_type === 'kickoff'}
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.distance}
                        isInvalid={formik.errors.distance}
                        placeholder='Distance'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.distance}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='ClockMinutes'>
                    <Form.Label>Clock Minutes</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='clock_minutes'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.clock_minutes}
                        isInvalid={formik.errors.clock_minutes}
                        placeholder='Clock Minutes'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.clock_minutes}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='ClockSeconds'>
                    <Form.Label>Clock Seconds</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='clock_seconds'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.clock_seconds}
                        isInvalid={formik.errors.clock_seconds}
                        placeholder='Clock Seconds'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.clock_seconds}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label>Offense Team Name</Form.Label>
                  <Select
                    name='offense_team_name'
                    placeholder={handleAddOrEdit(formik.values.offense_team_name, 'Select offense')}
                    options={teamNameOptions}
                    components={formik.errors.offense_team_name ? { DropdownIndicator } : null}
                    styles={selectErrorStyle(formik.errors.offense_team_name)}
                    onChange={handleChangeSelect}
                    onBlur={() => formik.setFieldTouched('offense_team_name')}
                  />
                  {formik.errors.offense_team_name ? (<div style={{ fontSize: "14px" }} className='mt-1 text-danger'>
                    {formik.errors.offense_team_name}
                  </div>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='OffenseTeamID'>
                    <Form.Label>Offense Team ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='offense_team_id'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.offense_team_id}
                        isInvalid={formik.errors.offense_team_id}
                        placeholder='Offense Team ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.offense_team_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='DefenseTeamName'>
                    <Form.Label>Defense Team Name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='defense_team_name'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.defense_team_name}
                        isInvalid={formik.errors.defense_team_name}
                        placeholder='Defense Team Name'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.defense_team_name}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='DefenseTeamID'>
                    <Form.Label>Defense Team ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='defense_team_id'
                        type='number'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.defense_team_id}
                        isInvalid={formik.errors.defense_team_id}
                        placeholder='Defense Team ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.defense_team_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3 pt-2 border-secondary'>
                <Col>
                  <Form.Group controlId='Play Start Yardline'>
                    <Form.Label>Play Start Yardline</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='play_start_yardline'
                        type='number'
                        disabled={formik.values.play_type === 'kickoff'}
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.play_start_yardline}
                        isInvalid={formik.errors.play_start_yardline}
                        placeholder='Starting Yardline'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.play_start_yardline}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Play Start Zone</Form.Label>
                  <Select
                    name='play_start_zone'
                    styles={selectErrorStyle(formik.errors.play_start_zone)}
                    isDisabled={formik.values.play_type === 'kickoff'}
                    placeholder={handleAddOrEdit(formik.values.play_start_zone, 'pick team zone')}
                    options={teamNameOptions}
                    components={formik.errors.play_start_zone ? { DropdownIndicator } : null}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const event = { target: { name: 'play_start_zone', value: selectedOption.value } }
                        formik.handleChange(event)
                        formik.setFieldError('play_start_zone', '')
                      } else {
                        formik.setFieldValue('play_start_zone', '')
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('play_start_zone')}
                  />
                  {formik.errors.play_start_zone ? <div style={{ fontSize: "14px" }} className='mt-1 text-danger'>{formik.errors.play_start_zone}</div> : null}
                </Col>
                <Col>
                  <Form.Group controlId='Play End Yardline'>
                    <Form.Label>Play End Yardline</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='play_end_yardline'
                        type='number'
                        disabled={formik.values.play_type === 'kickoff'}
                        onChange={e => {
                          formik.handleChange(e)
                          formik.setFieldError('play_end_yardline', '')
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.play_end_yardline}
                        isInvalid={formik.errors.play_end_yardline}
                        placeholder='Ending Yardline'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.play_end_yardline}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Play End Zone</Form.Label>
                  <Select
                    name='play_end_zone'
                    isDisabled={formik.values.play_type === 'kickoff'}
                    components={formik.errors.play_end_zone ? { DropdownIndicator } : null}
                    styles={selectErrorStyle(formik.errors.play_end_zone)}
                    placeholder={handleAddOrEdit(formik.values.play_end_zone, 'zone where play ended')}
                    options={teamNameOptions}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const event = { target: { name: 'play_end_zone', value: selectedOption.value } }
                        formik.handleChange(event)
                        formik.setFieldError('play_end_zone', '')
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('play_end_zone')}
                  />
                  {formik.errors.play_end_zone ? <div style={{ fontSize: "14px" }} className='mt-1 text-danger'>{formik.errors.play_end_zone}</div> : null}

                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label>Quarterback</Form.Label>

                  <Select
                    name='quarterback'
                    isDisabled={(formik.values.play_type === 'run' || formik.values.play_type === 'pass' ||
                      formik.values.play_type === '2pc-run' || formik.values.play_type === '2pc-pass' ? false : true)}
                    placeholder={handleAddOrEdit(formik.values.quarterback, 'Select Quarterback')}
                    options={offensePlayerSelect}
                    components={formik.errors.quarterback ? { DropdownIndicator } : null}
                    styles={selectErrorStyle(formik.errors.quarterback)}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const event = { target: { name: 'quarterback', value: selectedOption.value } }
                        formik.handleChange(event)
                        formik.setFieldError('quarterback', '')
                        idLookup(selectedOption.value, allTeamPlayers, 'quarterback_id')
                      } else {
                        formik.setFieldValue('quarterback', '')
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('quarterback')}
                  />
                  {formik.touched.quarterback && formik.errors.quarterback
                    ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>{formik.errors.quarterback}</div>) : null}

                </Col>
                <Col>
                  <Form.Group controlId='QuarterbackID'>
                    <Form.Label>Quarterback ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='quarterback_id'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.quarterback_id}
                        isInvalid={formik.errors.quarterback_id}
                        placeholder='Quarterback ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.quarterback_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>QB Position</Form.Label>
                  <Select
                    name='qb_position'
                    options={qbPosition}
                    components={formik.errors.qb_position ? { DropdownIndicator } : null}
                    styles={selectErrorStyle(formik.errors.qb_position)}
                    isDisabled={formik.values.play_type === 'punt' || formik.values.play_type === 'kickoff' || formik.values.play_type === 'point after touchdown'}
                    placeholder={handleAddOrEdit(formik.values.qb_position, 'Select QB position...')}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const event = { target: { name: 'qb_position', value: selectedOption.value } }
                        formik.handleChange(event)
                        formik.setFieldError('qb_position', '')
                      } else {
                        formik.setFieldValue('qb_position', '')
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('qb_position')}
                  />
                  {formik.touched.qb_position && formik.errors.qb_position
                    ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>
                      {formik.errors.qb_position}
                    </div>) : null}
                </Col>
                <Col>
                  <Form.Label>Personnel</Form.Label>
                  <Select
                    name='personnel'
                    isClearable
                    isDisabled={formik.values.play_type === 'kickoff'}
                    styles={selectErrorStyle(formik.errors.personnel)}
                    options={personnelOptions}
                    placeholder={handleAddOrEdit(formik.values.personnel, 'Select personnel...')}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const event = { target: { name: 'personnel', value: selectedOption.value } }
                        formik.handleChange(event)
                        formik.setFieldError('personnel', "")
                      } else {
                        formik.setFieldValue('personnel', '')
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('personnel')}
                  />
                </Col>
              </Row>

              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='PlayDescription'>
                    <Form.Label>Play Description</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name='play_description'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.play_description}
                        isInvalid={formik.errors.play_description}
                        placeholder='Play Description'
                        onBlur={() => formik.setFieldTouched('play_description')}
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.play_description}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='PlayDirection'>
                    <Form.Label>Play Direction</Form.Label>
                    {props.play.source === 'edit' && (
                      <Select
                        name='play_direction'
                        menuPlacement="auto"
                        value={value}
                        defaultValue={play.Play_direction ? { label: play.Play_direction, value: play.Play_direction } : 'Select Play Direction'}
                        options={playDirections}
                        onChange={handleChangeSelect}
                        onBlur={() => formik.setFieldTouched('play_direction')}
                        placeholder='Select Play Direction'
                      />
                    )}
                    {props.play.source === 'add' && (
                      <Select
                        name='play_direction'
                        options={playDirections}
                        onChange={handleChangeSelect}
                        onBlur={() => formik.setFieldTouched('play_direction')}
                        placeholder='Select Play Direction'
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Scoring Play'
                    name='scoring_play'
                    checked={formik.values.scoring_play}
                    id='scoring_play'
                    onChange={e => { formik.handleChange(e) }} />
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Penalty'
                    name='penalty'
                    checked={formik.values.penalty}
                    id='penalty'
                    onChange={e => { formik.handleChange(e) }} />
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Turnover'
                    name='turnover'
                    checked={formik.values.turnover}
                    id='turnover'
                    onChange={e => { formik.handleChange(e) }}
                  />
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Timeout'
                    name='timeout'
                    checked={formik.values.timeout}
                    id='timeout'
                    onChange={e => { formik.handleChange(e) }}
                  />
                </Col>
                {formik.values.timeout === true && (
                  <Col>
                    <Form.Label>Timeout Team</Form.Label>
                    <Select
                      name='timeout_team'
                      components={formik.errors.timeout_team ? { DropdownIndicator } : null}
                      styles={selectErrorStyle(formik.errors.timeout_team)}
                      placeholder={handleAddOrEdit(formik.values.timeout_team, 'team that took timeout')}
                      options={teamNameOptions}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'timeout_team', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('timeout_team', '')
                        }
                      }}
                      onBlur={() => {
                        return formik.setFieldTouched('timeout_team');
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Row>
            {/*************** Kickoff Data ******************/}
            {formik.values.play_type === 'kickoff' && (
              <>
                <Card.Subtitle as={Row} className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Kickoff Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2 '>
                    <Col>
                      <Form.Check
                        inline
                        type={'checkbox'}
                        label='Touchback'
                        name='kickoff_touchback'
                        disabled={formik.values.kickoff_fair_catch}
                        checked={formik.values.kickoff_touchback}
                        id='kickoff_touchback'
                        onChange={e => { formik.handleChange(e) }} />
                      <Form.Check
                        inline
                        type={'checkbox'}
                        label='Fair Catch'
                        name='kickoff_fair_catch'
                        disabled={formik.values.kickoff_touchback}
                        checked={formik.values.kickoff_fair_catch}
                        id='kickoff_fair_catch'
                        onChange={e => { formik.handleChange(e) }} />
                    </Col>
                  </Row>
                  <Row className='mb-3 border-secondary'>
                    <Col>
                      <Form.Group controlId='Kicker'>
                        <Form.Label>Kicker</Form.Label>
                        <Select
                          name='kicker'
                          options={offensePlayerSelect}
                          placeholder={handleAddOrEdit(formik.values.kicker, 'Kicker')}
                          onChange={selectedOption => {
                            if (selectedOption) {
                              const event = { target: { name: 'kicker', value: selectedOption.value } }
                              formik.handleChange(event)
                              formik.setFieldError('kicker', '')
                              idLookup(selectedOption.value, allTeamPlayers, 'kicker_id')
                            }
                          }}
                          onBlur={() => formik.setFieldTouched('kicker')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='kickerID'>
                        <Form.Label>Kicker ID</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='kicker_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                            }}
                            value={formik.values.kicker_id}
                            isInvalid={formik.errors.kicker_id}
                            placeholder='Kicker ID'
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='KickReturnPlayer'>
                        <Form.Label>Kick Return Player</Form.Label>
                        <Select
                          name='kick_return_player'
                          isClearable
                          isSearchable
                          options={defensePlayerSelect}
                          placeholder={handleAddOrEdit(formik.values.kick_return_player, "Kick returner")}
                          onChange={selectedOption => {
                            if (selectedOption) {
                              const event = { target: { name: 'kick_return_player', value: selectedOption.value } }
                              formik.handleChange(event)
                              formik.setFieldError('kick_return_player', '')
                              idLookup(selectedOption.value, allTeamPlayers, 'kick_return_player_id')
                            }
                          }}
                          onBlur={() => formik.setFieldTouched('kick_return_player')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group control='kick_return_player_id'>
                        <Form.Label>Kick Return Player ID</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='kick_return_player_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                            }}
                            value={formik.values.kick_return_player_id}
                            isInvalid={formik.errors.kick_return_player_id}
                            placeholder={'Kick returner id'}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Group controlId='Kickoff from yardline'>
                        <Form.Label>Kickoff From Yardline</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='kickoff_from_yardline'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                            }}
                            value={formik.values.kickoff_from_yardline}
                            isInvalid={formik.errors.kickoff_from_yardline}
                            placeholder='Kickoff yardline'
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Label>Kickoff Received On</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          name='kickoff_received_on'
                          type='number'
                          disabled={formik.values.kickoff_touchback}
                          onChange={e => {
                            formik.handleChange(e)
                          }}
                          value={formik.values.kickoff_received_on}
                          isInvalid={formik.errors.kickoff_received_on}
                          placeholder='Kickoff caught on'
                          onBlur={() => formik.setFieldTouched('kickoff_received_on')}
                        />
                        <Form.Control.Feedback type='invalid'>{formik.errors.kickoff_received_on}</Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>Kickoff Received Zone</Form.Label>
                      <Select
                        name='kickoff_received_zone'
                        placeholder={handleAddOrEdit(formik.values.kickoff_received_zone, 'pick zone')}
                        options={teamNameOptions}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'kickoff_received_zone', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('kickoff_received_zone', '')
                          } else {
                            formik.setFieldValue('kickoff_received_zone', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('kickoff_received_zone')}
                      />
                      {formik.errors.kickoff_received_zone ? <Alert variant='danger'>{formik.errors.kickoff_received_zone}</Alert> : null}
                    </Col>
                    <Col>
                      <Form.Group controlId='KickoffReturnedTo'>
                        <Form.Label>Kickoff Returned To</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='kickoff_returned_to'
                            type='number'
                            disabled={formik.values.kickoff_touchback || formik.values.kickoff_fair_catch}
                            onChange={e => {
                              formik.handleChange(e)
                            }}
                            value={formik.values.kickoff_returned_to}
                            isInvalid={formik.errors.kickoff_returned_to}
                            placeholder='Kickoff return yards'
                            onBlur={() => formik.setFieldTouched('kickoff_returned_to')}
                          />
                          <Form.Control.Feedback type="invalid">{formik.errors.kickoff_returned_to}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row className='mb-3'>
                    <Col sm={3}>
                      <Form.Label>Kickoff Returned Zone</Form.Label>
                      <Select
                        name='kickoff_returned_zone'
                        isClearable
                        placeholder={handleAddOrEdit(formik.values.kickoff_returned_zone, 'pick zone')}
                        options={teamNameOptions}
                        isDisabled={formik.values.kickoff_fair_catch || formik.values.kickoff_touchback}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'kickoff_returned_zone', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('kickoff_returned_zone', '')
                          } else {
                            formik.setFieldValue('kickoff_returned_zone', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('kickoff_returned_zone')}
                      />
                      {formik.errors.kickoff_returned_zone ? <Alert variant='danger'>{formik.errors.kickoff_returned_zone}</Alert> : null}
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId='Kickoff Return Yards'>
                        <Form.Label>Kickoff Return Yards</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='kickoff_return_yards'
                            type='number'
                            disabled={formik.values.kickoff_touchback || formik.values.kickoff_fair_catch}
                            onChange={e => {
                              formik.handleChange(e)
                            }}
                            value={formik.values.kickoff_return_yards}
                            isInvalid={formik.errors.kickoff_return_yards}
                            placeholder='Return yards'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.kickoff_return_yards}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {formik.values.play_type === 'point after touchdown' && (
              <>
                <Card.Subtitle as={Row} className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Point After Touchdown Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2 border-secondary'>
                    <Col>
                      <Form.Label>PAT Kicker</Form.Label>
                      <Select
                        name='pat_kicker'
                        value={formik.values.pat_kicker}
                        options={offensePlayerSelect}
                        placeholder={handleAddOrEdit(formik.values.pat_kicker, " PAT Kicker")}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'pat_kicker', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('pat_kicker', '')
                            idLookup(selectedOption.value, allTeamPlayers, 'pat_kicker_id')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('pat_kicker')}
                      />
                    </Col>
                    <Col>
                      <Form.Label>PAT Kicker ID</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          disabled
                          name='pat_player_id'
                          type='text'
                          value={formik.values.pat_kicker_id}
                          placeholder={'PAT Kicker ID'}
                        />
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>PAT Yards</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          name={'pat_yards'}
                          type='number'
                          onChange={e => { formik.handleChange(e) }}
                          value={formik.values.pat_yards}
                          isInvalid={formik.errors.pat_yards}
                          placeholder='PAT yards'
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {/************************ Field goal data ****************/}
            {formik.values.play_type === 'fieldgoal' && (
              <>
                <Card.Subtitle as={Row} className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Field Goal Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2 border-secondary'>
                    <Col>
                      <Form.Label>Kicker</Form.Label>
                      <Select
                        name='field_goal_kicker'
                        value={formik.values.field_goal_kicker}
                        options={offensePlayerSelect}
                        placeholder={handleAddOrEdit(formik.values.field_goal_kicker, "Kicker")}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'field_goal_kicker', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('field_goal_kicker', '')
                            idLookup(selectedOption.value, allTeamPlayers, 'field_goal_kicker_id')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('field_goal_kicker')}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Field Goal Kicker ID</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          disabled
                          name='field_goal_player_id'
                          type='text'
                          value={formik.values.field_goal_kicker_id}
                          placeholder={'Kicker ID'}
                        />
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>Field Goal Yards</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          name={'field_goal_yards'}
                          type='number'
                          onChange={e => { formik.handleChange(e) }}
                          value={formik.values.field_goal_yards}
                          isInvalid={formik.errors.field_goal_yards}
                          placeholder='Field goal yards'
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {/*********************** Punt Data ***********************/}
            {formik.values.play_type === 'punt' && (
              <>
                <Card.Subtitle as={Row} className='pb-1 ps-3 mt-2 fw-bold text-white rounded-top bg-secondary'>
                  Punt Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2 border-secondary'>
                    <Col>
                      <Form.Group controlId="punter">
                        <Form.Label>Punter</Form.Label>
                        <Select
                          name='punter'
                          value={formik.values.punter}
                          options={offensePlayerSelect}
                          placeholder={handleAddOrEdit(formik.values.punter, 'Punter')}
                          onChange={selectedOption => {
                            if (selectedOption) {
                              const event = { target: { name: 'punter', value: selectedOption.value } }
                              formik.handleChange(event)
                              formik.setFieldError('punter', '')
                              idLookup(selectedOption.value, allTeamPlayers, 'punter_id')
                            }
                          }}
                          onBlur={() => formik.setFieldTouched('punter')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="PunterID">
                        <Form.Label>Punter ID</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='punter_id'
                            type="text"
                            value={formik.values.punter_id}
                            placeholder="Punter ID"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Label>Punt Return Player</Form.Label>
                      <Select
                        name='punt_return_player'
                        isClearable
                        isSearchable
                        options={defensePlayerSelect}
                        placeholder={handleAddOrEdit(formik.values.punt_return_player, "Punt returner")}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'punt_return_player', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('punt_return_player', '')
                            idLookup(selectedOption.value, allTeamPlayers, 'punt_return_player_id')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('punt_return_player')}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Punt Return Player ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          disabled
                          name='punt_return_player_id'
                          type="text"
                          value={formik.values.punt_return_player_id}
                          placeholder="Punt return player id" />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Label>Punt Yards</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name='punt_yards'
                          disabled
                          type='number'
                          value={formik.values.punt_yards}
                          isInvalid={formik.errors.punt_yards}
                          placeholder='Punt yards'
                        />
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>Punt Caught Yardline</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name='punt_received_on'
                          type='number'
                          onChange={e => {
                            formik.handleChange(e)
                          }}
                          value={formik.values.punt_received_on}
                          isInvalid={formik.errors.punt_received_on}
                          placeholder='Punt caught yardline'
                        />
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>Punt Return Yards</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name='punt_return_yards'
                          type='number'
                          onChange={e => {
                            formik.handleChange(e)
                          }}
                          value={formik.values.punt_return_yards}
                          isInvalid={formik.errors.punt_return_yards}
                          placeholder='Punt return yards'
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Check
                        inline
                        disabled={formik.values.punt_fair_catch}
                        type={'checkbox'}
                        label='Touchback'
                        name='punt_touchback'
                        checked={formik.values.punt_touchback}
                        id='punt_touchback'
                        onChange={e => { formik.handleChange(e) }} />
                      <Form.Check
                        inline
                        disabled={formik.values.punt_touchback}
                        type={'checkbox'}
                        label='Fair Catch'
                        name='punt_fair_catch'
                        checked={formik.values.punt_fair_catch}
                        id='punt_fair_catch'
                        onChange={e => { formik.handleChange(e) }} />
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {/*********************** Run Play Data *******************/}
            {(formik.values.play_type === 'run' || formik.values.play_type === '2pc-run') && (
              <>
                <Card.Subtitle
                  as={Row}
                  className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Run Play Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2 border-secondary'>

                    <Col>
                      <Form.Group controlId='Runner'>
                        <Form.Label>Runner</Form.Label>
                        <Select
                          name='runner'
                          isClearable
                          isSearchable
                          options={offensePlayerSelect}
                          placeholder={handleAddOrEdit(formik.values.runner, "Ball carrier")}
                          onChange={selectedOption => {
                            if (selectedOption) {
                              const event = { target: { name: 'runner', value: selectedOption.value } }
                              formik.handleChange(event)
                              formik.setFieldError('runner', '')
                              idLookup(selectedOption.value, allTeamPlayers, 'runner_id')
                            }
                          }}
                          onBlur={() => formik.setFieldTouched('runner')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='RunnerID'>
                        <Form.Label>Runner ID</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='runner_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.runner_id}
                            isInvalid={formik.errors.runner_id}
                            placeholder='Runner ID'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.runner_id}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Group controlId='RunYards'>
                        <Form.Label>Run Yards</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='run_yards'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.run_yards}
                            isInvalid={formik.errors.run_yards}
                            placeholder='Run Yards'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.run_yards}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='Yards After Contact'>
                        <Form.Label>Yards After Contact</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='yards_after_contact'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.yards_after_contact}
                            isInvalid={formik.errors.yards_after_contact}
                            placeholder='Yards after contact'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.yards_after_contact}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </>
            )}

            {(formik.values.play_type === 'pass' || formik.values.play_type === '2pc-pass') && (
              <>
                <Card.Subtitle as={Row}
                  className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Pass Play Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2'>
                    <Col>
                      <Form.Label>Passer</Form.Label>
                      <Select
                        name='passer'
                        value={formik.values.passer}
                        options={offensePlayerSelect}
                        placeholder={handleAddOrEdit(formik.values.passer, 'Passer')}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'passer', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('passer', '')
                            idLookup(selectedOption.value, allTeamPlayers, 'passer_id')
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Group controlId='PasserID'>
                        <Form.Label>Passer ID</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='passer_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.passer_id}
                            isInvalid={formik.errors.passer_id}
                            placeholder='Passer ID'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.passer_id}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Label>Receiver</Form.Label>
                      <Select
                        name='receiver'
                        value={formik.values.receiver}
                        options={offensePlayerSelect}
                        placeholder={handleAddOrEdit(formik.values.receiver, 'Receiver')}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'receiver', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('receiver', '')
                            idLookup(selectedOption.value, allTeamPlayers, 'receiver_id')
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Group controlId='ReceiverID'>
                        <Form.Label>Receiver ID</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='receiver_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.receiver_id}
                            isInvalid={formik.errors.receiver_id}
                            placeholder='Receiver ID'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.receiver_id}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Group controlId='Caught Yardline'>
                        <Form.Label>Caught on Yardline</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='reception_caught_yardline'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.reception_caught_yardline}
                            isInvalid={formik.errors.reception_caught_yardline}
                            placeholder='Caught on yardline'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.reception_caught_yardline}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='Down on Yardline'>
                        <Form.Label>Reception Down Yardline</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='reception_down_yardline'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.reception_down_yardline}
                            isInvalid={formik.errors.reception_down_yardline}
                            placeholder='Down on yardline'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.reception_down_yardline}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='Yards after catch'>
                        <Form.Label>Yards After Catch</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='yards_after_catch'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.yards_after_catch}
                            isInvalid={formik.errors.Yards_after_catch}
                            placeholder='Yards after catch'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.yards_after_catch}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='Pass Yards'>
                        <Form.Label>Pass Yards</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='pass_yards'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.pass_yards}
                            isInvalid={formik.errors.pass_yards}
                            placeholder='Pass Yards'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.pass_yards}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Label>Incompletion Type</Form.Label>
                      <Select
                        name='incompletion_type'
                        isClearable
                        isSearchable
                        placeholder={handleAddOrEdit(formik.values.incompletion_type, 'Incompletion type')}
                        options={incompletionTypes}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'incompletion_type', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('incompletion_type', '')
                          } else {
                            formik.setFieldValue('incompletion_type', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('incompletion_type')}
                      />
                      {formik.errors.incompletion_type ? (<Alert variant='danger'>{formik.errors.incompletion_type}</Alert>) : null}
                    </Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                </Row>
              </>
            )}
            {formik.values.scoring_play && (
              <>
                <Card.Subtitle as={Row}
                  className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Scoring Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2'>
                    <Col>
                      <Form.Group controlId='HomeTeamScore'>
                        <Form.Label>{props.play.homeTeam} Score</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='home_team_score'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.home_team_score}
                            isInvalid={formik.errors.home_team_score}
                            placeholder='Home Team Score'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.home_team_score}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='AwayTeamScore'>
                        <Form.Label>{props.play.awayTeam} Score</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='away_team_score'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.away_team_score}
                            isInvalid={formik.errors.away_team_score}
                            placeholder='Away Team Score'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.away_team_score}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Group controlId='OffensiveScoreResult'>
                        <Form.Label>Offense Score Result</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='offensive_score_result'
                            type='number'
                            disabled={formik.values.defensive_score_result > 0}
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.offensive_score_result}
                            isInvalid={formik.errors.offensive_score_result}
                            placeholder='Offensive Score Result'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.offensive_score_result}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='DefensiveScoreResult'>
                        <Form.Label>Defense Score Result</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='defensive_score_result'
                            type='number'
                            disabled={formik.values.offensive_score_result > 0}
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.defensive_score_result}
                            isInvalid={formik.errors.defensive_score_result}
                            placeholder='Defensive Score Result'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.defensive_score_result}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className=' mb-3 justify-content-md-center'>
                    <Col lg='3' className='text-center'>
                      <Button type='button' className='mt-3 btn-primary' disabled={disableScoreButton} onClick={() => {
                        calculateScore()
                        setDisableScoreButton(true)
                      }}>Update Score
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {formik.values.penalty && (
              <>
                <Card.Subtitle as={Row}
                  className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Penalty Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2'>
                    <Col>
                      <Form.Check
                        inline
                        type={'checkbox'}
                        label='Offsetting Penalties'
                        name='offsetting_penalties'
                        checked={formik.values.offsetting_penalties}
                        id='offsetting_penalties'
                        onChange={e => { formik.handleChange(e) }} />
                      <Form.Check
                        inline
                        type={'checkbox'}
                        label='Penalty declined'
                        name='penalty_declined'
                        checked={formik.values.penalty_declined}
                        id='penalty_declined'
                        onChange={e => { formik.handleChange(e) }} />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Group controlId='PenaltyYards'>
                        <OverlayTrigger
                          placement='top'
                          overlay={
                            <Tooltip id='penaltyYards'>
                              Use negative number if against offense
                            </Tooltip>
                          }
                        >
                          <Form.Label>Penalty Yards</Form.Label>
                        </OverlayTrigger>
                        <InputGroup hasValidation>
                          <Form.Control
                            name='penalty_yards'
                            type='number'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.penalty_yards}
                            isInvalid={formik.errors.penalty_yards}
                            placeholder='Penalty yards'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.penalty_yards}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Label>Penalized Team</Form.Label>
                      <Select
                        name='penalty_team'
                        placeholder={handleAddOrEdit(formik.values.penalty_team, 'Penalized team')}
                        options={teamNameOptions}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'penalty_team', value: selectedOption.value } }
                            formik.handleChange(event)
                            handleSetPenaltyPlayers(selectedOption.value)
                            formik.setFieldError('penalty_team', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('penalty_team')}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Penalized Player</Form.Label>
                      <Select
                        name='penalty_player'
                        isClearable
                        placeholder={handleAddOrEdit(formik.values.penalty_player, 'Penalized player')}
                        options={penaltyPlayerSelect}
                        onChange={(selectedOption, { action }) => {
                          //console.log('here is action: ', action)
                          if (selectedOption) {
                            const event = { target: { name: 'penalty_player', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('penalty_player', '')
                            idLookup(selectedOption.value, allTeamPlayers, 'penalty_player_id')
                          } else {
                            formik.setFieldValue('penalty_player', '')
                            formik.setFieldValue('penalty_player_id', '')
                          }
                        }}
                      />
                      {formik.touched.penalty_player && formik.errors.penalty_player
                        ? (<Alert variant='danger'>{formik.errors.penalty_player}</Alert>) : null}
                    </Col>
                    <Col>
                      <Form.Group controlId='Penalty_Player_ID'>
                        <Form.Label>Penalty Player Id</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='penalty_player_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.penalty_player_id}
                            isInvalid={formik.errors.penalty_player_id}
                            placeholder='Penalty Player Id'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.penalty_player_id}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Label>Penalty Name</Form.Label>
                      <Select
                        name='penalty name'
                        value={value}
                        placeholder={handleAddOrEdit(formik.values.penalty_name, 'Penalty name')}
                        components={formik.errors.penalty_name ? { DropdownIndicator } : null}
                        styles={selectErrorStyle(formik.errors.penalty_name)}
                        options={penalties}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'penalty_name', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('penalty_name', "")
                          } else {
                            formik.setFieldValue('penalty_name', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('penalty_name')}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Penalized Team B</Form.Label>
                      <Select
                        name='penalty_team_B'
                        placeholder={handleAddOrEdit(formik.values.penalty_team_B, 'Penalized team')}
                        options={teamNameOptions}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'penalty_team_B', value: selectedOption.value } }
                            formik.handleChange(event)
                            handleSetPenaltyPlayers(selectedOption.value)
                            formik.setFieldError('penalty_team_B', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('penalty_team_B')}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Penalized Player B</Form.Label>
                      <Select
                        name='penalty_player_b'
                        isClearable
                        placeholder={handleAddOrEdit(formik.values.penalty_player_B, 'Penalized player')}
                        options={penaltyPlayerSelect}
                        onChange={(selectedOption, { action }) => {
                          //console.log('here is action: ', action)
                          if (selectedOption) {
                            const event = { target: { name: 'penalty_player_B', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('penalty_player_B', '')
                            idLookup(selectedOption.value, allTeamPlayers, 'penalty_player_B_id')
                          } else {
                            formik.setFieldValue('penalty_player_B', '')
                            formik.setFieldValue('penalty_player_B_id', '')
                          }
                        }}
                      />
                      {formik.touched.penalty_player_B && formik.errors.penalty_player_B
                        ? (<Alert variant='danger'>{formik.errors.penalty_player_B}</Alert>) : null}
                    </Col>
                    <Col>
                      <Form.Label>Penalty Player B Id</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          disabled
                          name='penalty_player_B_id'
                          type='text'
                          onChange={e => {
                            formik.handleChange(e)
                            ////setIsBlocking(e.target.value.length > 0)
                          }}
                          value={formik.values.penalty_player_B_id}
                          isInvalid={formik.errors.penalty_player_B_id}
                          placeholder='Penalty Player B Id'
                        />
                        <Form.Control.Feedback type='invalid'>{formik.errors.penalty_player_B_id}</Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label>Penalty Name B</Form.Label>
                      <Select
                        name='penalty_name_B'
                        placeholder={handleAddOrEdit(formik.values.penalty_name_B, 'Penalty name B')}
                        components={formik.errors.penalty_name_B ? { DropdownIndicator } : null}
                        styles={selectErrorStyle(formik.errors.penalty_name_B)}
                        options={penalties}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'penalty_name_B', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('penalty_name_B', "")
                          } else {
                            formik.setFieldValue('penalty_name_B', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('penalty_name_B')}
                      />
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col xs={3}>
                      <Form.Label>Penalty Enforced At</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          name='penalty_enforced_yardline'
                          type='number'
                          onChange={e => {
                            formik.handleChange(e)
                            ////setIsBlocking(e.target.value.length > 0)
                          }}
                          value={formik.values.penalty_enforced_yardline}
                          isInvalid={formik.errors.penalty_enforced_yardline}
                          placeholder='Enter yardline'
                        />
                        <Form.Control.Feedback type='invalid'>{formik.errors.penalty_enforced_yardline}</Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col xs={3}>
                      <Form.Label>Enforced Foul Zone</Form.Label>
                      <Select name='penalty_enforced_zone'
                        placeholder={handleAddOrEdit(formik.values.penalty_enforced_zone, 'Zone enforced')}
                        options={teamNameOptions}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            const event = { target: { name: 'penalty_enforced_zone', value: selectedOption.value } }
                            formik.handleChange(event)
                            formik.setFieldError('penalty_enforced_zone', '')
                          }
                        }}
                        onBlur={() => formik.setFieldTouched('penalty_enforced_zone')} />
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {formik.values.turnover && (
              <>
                <Card.Subtitle
                  as={Row}
                  className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
                  Turnover Data
                </Card.Subtitle>
                <Row className='border border-secondary rounded-bottom'>
                  <Row className='mb-3 pt-2 border-secondary'>
                    <Col>
                      <Form.Group controlId='TurnoverType'>
                        <Form.Label>Turnover Type</Form.Label>
                        {props.play.source === 'edit' && (
                          <Select
                            name='turnover_type'
                            value={value}
                            defaultValue={play.turnoverType ? { label: play.turnover_type, value: play.turnover_type } : 'Select Turnover Type'}
                            options={turnoverOptions}
                            onChange={handleChangeSelect}
                            onBlur={() => formik.setFieldTouched('turnover_type')}
                            placeholder='Select Turnover Type'
                          />
                        )}
                        {props.play.source === 'add' && (
                          <Select
                            isClearable
                            menuPlacement="top"
                            name='turnover_type'
                            options={turnoverOptions}
                            onChange={handleChangeSelect}
                            menuShouldScrollIntoView
                            onBlur={() => formik.setFieldTouched('turnover_type')}
                            placeholder='Select Turnover Type'
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='Turnover Player'>
                        <Form.Label>Turnover Player</Form.Label>
                        <Select
                          name='turnover_player'
                          isClearable
                          isSearchable
                          isDisabled={disableOffensePlayer}
                          options={offensePlayerSelect}
                          placeholder={handleAddOrEdit(formik.values.turnover_player, "Turnover player")}
                          onChange={selectedOption => {
                            if (selectedOption) {
                              const event = { target: { name: 'turnover_player', value: selectedOption.value } }
                              formik.handleChange(event)
                              formik.setFieldError('turnover_player', '')
                              idLookup(selectedOption.value, allTeamPlayers, 'turnover_player_id')
                            }
                          }}
                          onBlur={() => formik.setFieldTouched('turnover_player')}
                        />
                        {formik.touched.turnover_player && formik.errors.turnover_player
                          ? (<Alert variant='danger'>{formik.errors.turnover_player}</Alert>) : null}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='Turnover_Player_ID'>
                        <Form.Label>Turnover Player Id</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='turnover_player_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.turnover_player_id}
                            isInvalid={formik.errors.turnover_player_id}
                            placeholder='Turnover Player Id'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.turnover_player_id}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <Form.Group controlId='Turnover Recovered By'>
                        <Form.Label>Turnover Recovered By</Form.Label>
                        <Select
                          name='turnover_recoverd_by'
                          isClearable
                          isSearchable
                          menuPlacement='top'
                          options={defensePlayerSelect}
                          placeholder={handleAddOrEdit(formik.values.turnover_recoverd_by, "Turnover recovered by")}
                          onChange={selectedOption => {
                            if (selectedOption) {
                              const event = { target: { name: 'turnover_recovered_by', value: selectedOption.value } }
                              formik.handleChange(event)
                              formik.setFieldError('turnover_recovered_by', '')
                              idLookup(selectedOption.value, allTeamPlayers, 'turnover_recovered_by_id')
                            }
                          }}
                          onBlur={() => formik.setFieldTouched('turnover_recovered_by')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='Turnover_Recovered_By_ID'>
                        <Form.Label>Turnover Recovered By Id</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            disabled
                            name='turnover_recovered_by_id'
                            type='text'
                            onChange={e => {
                              formik.handleChange(e)
                              ////setIsBlocking(e.target.value.length > 0)
                            }}
                            value={formik.values.turnover_recovered_by_id}
                            isInvalid={formik.errors.turnover_recovered_by_id}
                            placeholder='Turnover Recovered By Id'
                          />
                          <Form.Control.Feedback type='invalid'>{formik.errors.turnover_recovered_by_id}</Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {/* Defense Data*/}
            <Card.Subtitle
              as={Row}
              className='pb-1 ps-3 mt-2 fw-bold text-white bg-secondary rounded-top'>
              Defense Data
            </Card.Subtitle>
            <Row className='border border-secondary rounded-bottom'>
              <Row className='mb-3 pt-2 border-secondary'>
                <Col>
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Assisted Tackle'
                    name='assisted_tackle'
                    checked={formik.values.assisted_tackle}
                    disabled={formik.values.sack}
                    id='assisted_tackle'
                    onChange={e => { formik.handleChange(e) }}
                  />
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Sack'
                    name='sack'
                    checked={formik.values.sack}
                    disabled={formik.values.assisted_tackle}
                    id='sack'
                    onChange={e => { formik.handleChange(e) }}
                  />
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Forced out of bounds'
                    name='ob_forced'
                    checked={formik.values.ob_forced}
                    disabled={formik.values.ob}
                    id='ob_forced'
                    onChange={e => { formik.handleChange(e) }}
                  />
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Out of bounds'
                    name='ob'
                    checked={formik.values.ob}
                    disabled={formik.values.ob_forced}
                    id='ob_forced'
                    onChange={e => { formik.handleChange(e) }}
                  />
                  <Form.Check
                    inline
                    type={'checkbox'}
                    label='Kneel or Slide'
                    name='kneel_slide'
                    checked={formik.values.kneel_slide}
                    disabled={formik.values.ob_forced || formik.values.sack || formik.values.assisted_tackle}
                    id='kneel_slide'
                    onChange={e => { formik.handleChange(e) }}
                  />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Label>Defensive Front</Form.Label>
                  <Select
                    name='defense_formation'
                    isClearable
                    components={formik.errors.defense_formation ? { DropdownIndicator } : null}
                    styles={selectErrorStyle(formik.errors.defense_formation)}
                    placeholder={handleAddOrEdit(formik.values.defense_formation, 'Defensive Front')}
                    options={defensiveFronts}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        const event = { target: { name: 'defense_formation', value: selectedOption.value } }
                        formik.handleChange(event)
                        formik.setFieldError("defense_formation", "")
                      } else {
                        formik.setFieldValue('defense_formation', '')
                      }
                    }}
                    onBlur={() => formik.setFieldTouched('defense_formation')}
                  />
                  {formik.touched.defense_formation && formik.errors.defense_formation ?
                    (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>{formik.errors.defense_formation}</div>) : null
                  }
                </Col>
                <Col>
                  <Form.Group controlId='Coverage'>
                    <Form.Label>Coverage</Form.Label>
                    <Select
                      name='coverage'
                      options={coverageOptions}
                      components={formik.errors.coverage ? { DropdownIndicator } : null}
                      styles={selectErrorStyle(formik.errors.coverage)}
                      placeholder={handleAddOrEdit(formik.values.coverage, 'Coverage')}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'coverage', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('coverage', '')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('coverage')}
                    />
                    {formik.errors.coverage ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>
                      {formik.errors.coverage}
                    </div>) : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='SoloTackler'>
                    <Form.Label>Solo Tackler</Form.Label>
                    <Select
                      name='tackled_by_solo'
                      isClearable
                      options={formik.values.play_type === 'kickoff' || formik.values.play_type === 'punt' ? offensePlayerSelect : defensePlayerSelect}
                      isDisabled={formik.values.assisted_tackle || formik.values.sack}
                      components={formik.errors.tackled_by_solo ? { DropdownIndicator } : null}
                      placeholder={handleAddOrEdit(formik.values.tackled_by_solo, 'Tackled by')}
                      value={formik.values.tackled_by_solo}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'tackled_by_solo', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('tackled_by_solo', '')
                          // auto populate dependent ID field
                          idLookup(selectedOption.value, allTeamPlayers, 'tackled_by_solo_id')
                        } else {
                          formik.setFieldValue('tackled_by_solo', '')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('tackled_by_solo')}
                    />
                  </Form.Group>
                  {formik.errors.tackled_by_solo && formik.touched.tackled_by_solo ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>
                    {formik.errors.tackled_by_solo}
                  </div>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='TacklerID'>
                    <Form.Label>Tackler ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='tackled_by_solo_id'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.tackled_by_solo_id}
                        isInvalid={formik.errors.tackled_by_solo_id}
                        placeholder='Tackled by ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.tackled_by_solo_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='AssistTacklerA'>
                    <Form.Label>Assist Tackler A</Form.Label>
                    <Select
                      name='assist_tackler_A'
                      isClearable
                      isDisabled={!formik.values.assisted_tackle}
                      value={formik.values.assist_tackler_A}
                      components={formik.errors.assist_tackler_A ? { DropdownIndicator } : null}
                      styles={selectErrorStyle(formik.errors.assist_tackler_A)}
                      options={formik.values.play_type === 'kickoff' || formik.values.play_type === 'punt' ? offensePlayerSelect : defensePlayerSelect}
                      placeholder={handleAddOrEdit(formik.values.assist_tackler_A, 'Assist tackler')}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'assist_tackler_A', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('assist_tackler_A', '')
                          // auto populate dependent ID field
                          idLookup(selectedOption.value, allTeamPlayers, 'assist_tackler_A_id')
                        } else {
                          formik.setFieldValue('assist_tackler_A', '')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('assist_tackler_A')}
                    />
                  </Form.Group>
                  {formik.errors.assist_tackler_A && formik.touched.assist_tackler_A ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>{formik.errors.assist_tackler_A}</div>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='AssistTacklerAID'>
                    <Form.Label>Assist Tackler A ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='assist_tackler_A_id'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.assist_tackler_A_id}
                        isInvalid={formik.errors.assist_tackler_A_id}
                        placeholder='Assist Tackler A ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.assist_tackler_A_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='AssistTacklerB'>
                    <Form.Label>Assit Tackler B</Form.Label>
                    <Select
                      name='assist_tackler_B'
                      isClearable
                      value={formik.values.assist_tackler_B}
                      components={formik.errors.assist_tackler_B ? { DropdownIndicator } : null}
                      styles={selectErrorStyle(formik.errors.assist_tackler_B)}
                      isDisabled={!formik.values.assisted_tackle}
                      options={formik.values.play_type === 'kickoff' || formik.values.play_type === 'punt' ? offensePlayerSelect : defensePlayerSelect}
                      placeholder={handleAddOrEdit(formik.values.assist_tackler_B, 'Assist tackler')}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'assist_tackler_B', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('assist_tackler_B', '')
                          // auto populate dependent ID field
                          idLookup(selectedOption.value, allTeamPlayers, 'assist_tackler_B_id')
                        } else {
                          formik.setFieldValue('assist_tackler_B', '')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('assist_tackler_B')}
                    />
                  </Form.Group>
                  {formik.errors.assist_tackler_B ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>{formik.errors.assist_tackler_B}</div>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='AssistTacklerBID'>
                    <Form.Label>Assist Tackler B ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='assist_tackler_B_id'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.assist_tackler_B_id}
                        isInvalid={formik.errors.assist_tackler_B_id}
                        placeholder='Assist Tackler B ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.assist_tackler_B_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='Sacked By'>
                    <Form.Label>Sacked By</Form.Label>
                    <Select
                      name='sacked_by'
                      value={formik.values.sacked_by}
                      isDisabled={!formik.values.sack}
                      isSearchable
                      isClearable
                      options={defensePlayerSelect}
                      components={formik.errors.sacked_by ? { DropdownIndicator } : null}
                      styles={selectErrorStyle(formik.errors.sacked_by)}
                      placeholder={handleAddOrEdit(formik.values.sacked_by, 'Sacked by')}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'sacked_by', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('sacked_by', '')
                          // auto populate dependent ID field
                          idLookup(selectedOption.value, allTeamPlayers, 'sacked_by_id')
                        } else {
                          formik.setFieldValue('sacked_by', '')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('sacked_by')}
                    />
                  </Form.Group>
                  {formik.errors.sacked_by && formik.touched.sacked_by ? (<div style={{ fontSize: '14px' }} className='mt-1 text-danger'>{formik.errors.sacked_by}</div>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='AssistTacklerAID'>
                    <Form.Label>Sacked By ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='sacked_by_id'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.sacked_by_id}
                        isInvalid={formik.errors.sacked_by_id}
                        placeholder='Sacked by ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.sacked_by_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='Assist Sack By'>
                    <Form.Label>Assist Sack By</Form.Label>
                    <Select
                      name='assisted_sack_by'
                      isDisabled={!formik.values.sack}
                      value={formik.values.assisted_sack_by}
                      options={defensePlayerSelect}
                      components={formik.errors.assisted_sack_by ? { DropdownIndicator } : null}
                      styles={selectErrorStyle(formik.errors.assisted_sack_by)}
                      placeholder={handleAddOrEdit(formik.values.assisted_sack_by, 'Assist sack by')}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'assisted_sack_by', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('assisted_sack_by', '')
                          // auto populate dependent ID field
                          idLookup(selectedOption.value, allTeamPlayers, 'assisted_sack_by_id')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('assisted_sack_by')}
                    />
                  </Form.Group>
                  {formik.errors.assisted_sack_by ? (<Alert variant='danger'>{formik.errors.assisted_sack_by}</Alert>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='AssistSackedByID'>
                    <Form.Label>Assist Sacked ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='assisted_sack_by_id'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.assisted_sack_by_id}
                        isInvalid={formik.errors.assisted_sack_by_id}
                        placeholder='Assisted Sack By ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.assisted_sack_by_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Form.Group controlId='Hurried By'>
                    <Form.Label>Hurried By</Form.Label>
                    <Select
                      name='hurried_by'
                      isClearable
                      isDisabled={formik.values.play_type === 'kickoff'}
                      value={formik.values.hurried_by}
                      options={defensePlayerSelect}

                      placeholder={handleAddOrEdit(formik.values.hurried_by, 'Hurried by')}
                      onChange={selectedOption => {
                        if (selectedOption) {
                          const event = { target: { name: 'hurried_by', value: selectedOption.value } }
                          formik.handleChange(event)
                          formik.setFieldError('hurried_by', '')
                          // auto populate dependent ID field
                          idLookup(selectedOption.value, allTeamPlayers, 'hurried_by_id')
                        }
                      }}
                      onBlur={() => formik.setFieldTouched('hurried_by')}
                    />
                  </Form.Group>
                  {formik.errors.hurried_by ? (<Alert variant='danger'>{formik.errors.hurried_by}</Alert>) : null}
                </Col>
                <Col>
                  <Form.Group controlId='HurriedByID'>
                    <Form.Label>Hurried By ID</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        name='hurried_by_id'
                        type='text'
                        onChange={e => {
                          formik.handleChange(e)
                          ////setIsBlocking(e.target.value.length > 0)
                        }}
                        value={formik.values.hurried_by_id}
                        isInvalid={formik.errors.hurried_by_id}
                        placeholder='Hurried by ID'
                      />
                      <Form.Control.Feedback type='invalid'>{formik.errors.hurried_by_id}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </Row>


            <Row className='justify-content-md-center'>
              <Col lg='3' className='text-center'>
                <Button active='true' type='submit' className='mt-3 btn-primary' onClick={() => setValue(null)}>Submit</Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card >
    </>
  )
}

export default PBPForm