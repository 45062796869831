export const positionOptions = [
  { value: 'Center', label: 'Center' },
  { value: 'Offensive Guard', label: 'Offensive Guard' },
  { value: 'Right Offensive Guard', label: 'Right Offensive Guard' },
  { value: 'Left Offensive Guard', label: 'Left Offensive Guard' },
  { value: 'Offensive Tackle', label: 'Offensive Tackle' },
  { value: 'Right Offensive Tackle', label: 'Right Offensive Tackle' },
  { value: 'Left Offensive Tackle', label: 'Left Offensive Tackle' },
  { value: 'Quarterback', label: 'Quarterback' },
  { value: 'Running back', label: 'Running Back' },
  { value: 'Fullback', label: 'Fullback' },
  { value: 'Wide Receiver', label: 'Wide Receiver' },
  { value: 'Tight End', label: 'Tight End' },
  { value: 'Defensive Tackle', label: 'Defensive Tackle' },
  { value: 'Left Defensive Tackle', label: 'Left Defensive Tackle' },
  { value: 'Right Defensive Tackle', label: 'Right Defensive Tackle' },
  { value: 'Defensive End', label: 'Defensive End' },
  { value: 'Right Defensive End', label: 'Right Defensive End' },
  { value: 'Left Defensive End', label: 'Left Defensive End' },
  { value: 'Linebacker', label: 'Linebacker' },
  { value: 'Inside Linebacker', label: 'Inside Linebacker' },
  { value: 'Outside Linebacker', label: 'Outside Linebacker' },
  { value: 'Cornerbacker', label: 'Cornerbacker' },
  { value: 'Safety', label: 'Safety' },
  { value: 'Free Safety', label: 'Free Safety' },
  { value: 'Strong Safety', label: 'Strong Safety' },
  { value: 'Defensive Back', label: 'Defensive Back' },
  { value: 'Kicker', label: 'Kicker' },
  { value: 'Place Kicker', label: 'Place Kicker' },
  { value: 'Punter', label: 'Punter' },
  { value: 'Long Snapper', label: 'Long Snapper' }
]

export const statusOptions = [
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Active', label: 'Active' },
  { value: 'NFI', label: 'NFI' },
  { value: 'Injuired Reserve', label: 'Injuired Reserve' },
  { value: 'PUP', label: 'PUP' },
  { value: 'Reserve', label: 'Reserve' },
  { value: 'Suspended', label: 'Suspended' }
]

export const seasonOptions = [
  { value: 2008, label: '2008' },
  { value: 2009, label: '2009' },
  { value: 2010, label: '2010' },
  { value: 2011, label: '2011' },
  { value: 2012, label: '2012' },
  { value: 2013, label: '2013' },
  { value: 2014, label: '2014' },
  { value: 2015, label: '2015' },
  { value: 2016, label: '2016' },
  { value: 2017, label: '2017' },
  { value: 2018, label: '2018' },
  { value: 2019, label: '2019' },
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
  { value: 2024, label: '2024' },
  { value: 2025, label: '2025' },
  { value: 2026, label: '2026' },
  { value: 2027, label: '2027' },
  { value: 2028, label: '2028' },
  { value: 2029, label: '2029' },
  { value: 2030, label: '2030' },
]

/* export const weeks = [
  '1', '2', '3', '4', '5', '6','7', '8', '9', '10', '11', '12','13', '14', '15', '16', '17', '18'
] */

export const weekOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 }
]

export const seasonTypeOptions = [
  { value: 'Reg', label: 'Reg' },
  { value: 'Pre', label: 'Pre' },
  { value: 'Post', label: 'Post' }
]

export const playerStatusOptions = [
  { value: 'A', label: 'Active' },
  { value: 'D', label: 'Doubtful' },
  { value: 'IR', label: 'Injured Reserve' },
  { value: 'IR-R', label: 'IR - Return' },
  { value: 'GTD', label: 'Game Time Decision' },
  { value: 'NA', label: 'Not Active' },
  { value: 'O', label: 'Out next game' },
  { value: 'P', label: 'Probable' },
  { value: 'PUP', label: 'PUP' },
  { value: 'R-Covid', label: 'R-Covid' },
  { value: 'Q', label: 'Questionable' },
  { value: 'SSPD/SUSP', label: 'Suspended' },
  { value: 'T', label: 'Traded' }
]

export const trueFalseOptions = [
  { value: true, label: 'true' },
  { value: false, label: 'false' }
]