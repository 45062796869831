import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import ScheduleBody from './ScheduleBody'
import NoSchedule from './NoSchedule'
import NewSchedule from './NewSchedule'
import ViewMatchup from './ViewMatchup'
import EditSchedule from './EditSchedule'
import { getScheduleOptions } from '../lib/apiOptions'
import {seasonYearOptions} from './options'
import useAPI from '../hooks/useAPI'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Home from '../Pages/Home'


const Schedule = () => {
  const d = new Date()
  const currentYear = d.getFullYear()
  const currentMonth = d.getMonth()
  var currentSeason
  // if current month is January (0) or February (1) then subtract current year by 1 to stay in season
  if (currentMonth === 0 || 1) {
    currentSeason = currentYear - 1
  } else {
    currentSeason = currentYear
  }
  const seasonTypes = ['Pre', 'Regular', 'Post']
  const [selectedSeason, setSelectedSeason] = useState(currentSeason.toString())
  const [selectedSeasonType, setSelectedSeasonType] = useState('Regular')
  const [apiURL, setApiURL] = useState(`${getScheduleOptions.baseUrl}\\${selectedSeason}\\${selectedSeasonType}`)
  const { sendRequest, status, error, data: Schedule } = useAPI(getScheduleOptions)
  const [showSchedule, setShowSchedule] = useState(true)
  const [showNewSchedule, setShowNewSchedule] = useState(false)
  const [showMatchup, setShowMatchup] = useState(false)
  const [showEditMatchup, setShowEditMatchup] = useState(false)
  const [matchup, setMatchup] = useState()
  const [updateScheduleIndex, setUpdateScheduleIndex] = useState(0)
  const [newScheduleIndex, setNewScheduleIndex] = useState(0)
  const toggleScheduleView = () => setShowSchedule(showSchedule => !showSchedule)
  const toggleNewScheduleView = () => setShowNewSchedule(showNewSchedule => !showNewSchedule)
  const toggleMatchupView = () => setShowMatchup(showMatchup => !showMatchup)
  const toggleEditMatchupView = () => setShowEditMatchup(showEditMatchup => !showEditMatchup)

  const handleShowSchedule = () => {
    console.log('in handleShowSchedule')
   
    if (showNewSchedule) {toggleNewScheduleView()}
    if (showMatchup) {toggleMatchupView()}
    if (showEditMatchup) {toggleEditMatchupView()}
    toggleScheduleView()
  }
  const handleShowNewSchedule = () => {
    
    if (showSchedule) {toggleScheduleView()}
    if (showMatchup) {toggleMatchupView()}
    if (showEditMatchup) {toggleEditMatchupView()}
    toggleNewScheduleView()
  }
  const handleShowEditMatchup = (m) => {
    setMatchup(m)
    if (showNewSchedule) {toggleNewScheduleView()}
    if (showMatchup) {toggleMatchupView()}
    if(showSchedule) {toggleScheduleView()}
    toggleEditMatchupView()
  }
  const handleShowMatchup = (m) => {
    setMatchup(m)
    if (showNewSchedule) {toggleNewScheduleView()}
    if (showSchedule) {toggleScheduleView()}
    if (showEditMatchup) {toggleEditMatchupView()}
    toggleMatchupView()
  }

  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      getScheduleOptions.url = apiURL
      sendRequest(controller)
    }
    getTeams()
    return () => controller?.abort()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiURL])

  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      getScheduleOptions.url = apiURL
      sendRequest(controller)
    }
    getTeams()
    return () => controller?.abort()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateScheduleIndex])

  useEffect(() => {
    let controller = new AbortController()
    const getTeams = async () => {
      getScheduleOptions.url = apiURL
      sendRequest(controller)
    }
    getTeams()
    return () => controller?.abort()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newScheduleIndex])

  useEffect(() => {
    setApiURL(`${getScheduleOptions.baseUrl}\\${selectedSeason}\\${selectedSeasonType}`)
  }, [selectedSeason, selectedSeasonType])

  return (
    <Container>
      <Navbar bg='light' variant='light' className='mt-3' expand='lg'>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr.auto'>
            <NavDropdown title='Season' id='season-dropdown' 
              disabled={showNewSchedule ? true:false || showMatchup || showEditMatchup ? true:false}
            >
              {seasonYearOptions.map(season => (
                <NavDropdown.Item
                  key={season.value}
                  eventKey={season.value}
                  onClick={() => setSelectedSeason(season.value)}
                >
                  {season.value}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
          <Nav className='mr.auto'>
            <NavDropdown title='Season Type' id='season-type-dropdown' 
              disabled={showNewSchedule ? true:false || showMatchup || showEditMatchup ? true:false}
            >
              {seasonTypes.map(type => (
                <NavDropdown.Item
                  key={type}
                  eventKey={type}
                  onClick={() => setSelectedSeasonType(type)}
                >
                  {type}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
          <Nav onSelect={handleShowNewSchedule}>
                  <Nav.Item>
                    <Nav.Link eventKey='newTeam'>New Matchup</Nav.Link>
                  </Nav.Item>
                </Nav>
        </Navbar.Collapse>
      </Navbar>
      {status === 'complete' && error !== null && (<NoSchedule loadError={error} />)}
      {showSchedule && (
        <ScheduleBody
          schedule={Schedule} 
          status={status} 
          season={selectedSeason} 
          seasonType={selectedSeasonType}
          onView={handleShowMatchup}
          onEdit={handleShowEditMatchup} 
        />
      )}
      {showNewSchedule && (
        <NewSchedule 
          onBackClick={handleShowSchedule}
          scheduleAddIndex={newScheduleIndex}
          onAddNewSchedule={setNewScheduleIndex}
          onAddMatchupFinished={handleShowSchedule}
        />
      )}
      {showMatchup && (
        <ViewMatchup 
          onBackClick={handleShowSchedule}
          matchup={matchup}
        />
      )}
      {showEditMatchup && (
        <EditSchedule 
          onBackClick={handleShowSchedule}
          matchup={matchup}
          season={selectedSeason}
          seasonType={selectedSeasonType}
          scheduleUpdateIndex={updateScheduleIndex}
          onScheduleUpdate={setUpdateScheduleIndex}
        />
      )}
     
    </Container>
  )
}

export default withAuthenticationRequired(Schedule, {
  onRedirection: () => <Home />
})