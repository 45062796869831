import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { Pencil, Eye, ClockHistory } from 'react-bootstrap-icons'

const PlayerTable = (props) => {
  console.log('here is props.player: ', props.player)
  return (
    <Table striped hover responsive size='sm' className='mt-5' style={{ fontSize: 14 }}>
      <thead>
        <tr>
          <th className='col-2'>Edit - View - Transactions</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Season</th>
          <th>Team</th>
          <th>Number</th>
          <th>Position</th>
          <th>DOB</th>
          <th>Height</th>
          <th>Weight</th>
          <th>Draft Year</th>
          <th>Draft Round</th>
          <th>Draft Pick</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody key='tableBody'>
        {props.player === null && (<tr><td>NO PLAYERS</td></tr>)}
        {props.player != null && (props.player.map(player =>
          <tr key={player.Id}>
            <td>
              <Button variant='outline-dark' size="sm" className='ms-1 mt-1 pt-0 pb-0 ps-1 pe-1' onClick={() => props.onEditPlayer(player)}><Pencil /></Button>
              <Button variant='outline-dark' size="sm" className='ms-1 mt-1 pt-0 pb-0 ps-1 pe-1' onClick={() => props.onViewPlayer(player)}><Eye /></Button>
              <Button variant='outline-dark' size="sm" className='ms-1 mt-1 pt-0 pb-0 ps-1 pe-1' onClick={() => props.onShowTransactions(player)}><ClockHistory /></Button>
            </td>
            <td>{player.First_name}</td>
            <td>{player.Last_name}</td>
            <td>{player.Season}</td>
            <td>{player.Current_team_name}</td>
            <td>{player.Number}</td>
            <td>{player.Position}</td>
            <td>{player.Date_of_birth}</td>
            <td>{player.Height_feet} ft {player.Height_inches}</td>
            <td>{player.Weight_pounds}</td>
            <td>{player.Draft_year}</td>
            <td>{player.Draft_round}</td>
            <td>{player.Draft_pick}</td>
            <td>{player.Status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default PlayerTable