import React from 'react'
import { NavDropdown } from 'react-bootstrap'

const TeamSelect = (props) => {
  const Teams = props.Teams
  return (
    <>
    {Teams.map(team => (
      <NavDropdown.Item eventKey={team.Team_id} key={team.Team_id}>
        {team.Team_name}
      </NavDropdown.Item>
    ))}
    </>
  )
}

export default TeamSelect
