import React, { useEffect, useState} from 'react'
//import { Prompt } from 'react-router-dom' Prompt not supported in react-router-dom v6 yet
import { Alert, Card, Form, Row, Col, Button } from 'react-bootstrap'
import AlertDismissible from '../misc/AlertDismissable'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { seasonYearOptions, weekOptions, timezoneOptions } from './options'

const ScheduleForm = (props) => {
  var matchup = {}
  if (props.matchup !== null) {
    matchup = props.matchup
  }
  
  // const [isBlocking, //setIsBlocking] = useState(false) isBlocking is from Prompt which is not supported see above
  const [value, setValue] = useState()
  const [sameTeamError, setSameTeamError] = useState('')
  const [yearMatchError, setYearMatchError] = useState('')
  const seasonTypeOptions = [
    { value: 'Pre', label: 'Pre'},
    { value: 'Regular', label: 'Regular' },
    { value: 'Post', label: 'Post' }
  ]

  const formik = useFormik({
    initialValues: {
      Game_id: (matchup.Game_id ? matchup.Game_id : 0),
      Season_type: (matchup.Season_type ? matchup.Season_type : ''),
      Season_year: (matchup.Season_year ? matchup.Season_year : 0),
      Week: (matchup.Week ? matchup.Week : 0),
      Date: (matchup.Date ? matchup.Date :''),
      Timezone: (matchup.Timezone ? matchup.Timezone : ''),
      Start_time: (matchup.Start_time ? matchup.Start_time : ''),
      Away_team: (matchup.Away_team ? matchup.Away_team : ''),
      Away_team_id: (matchup.Away_team_id ? matchup.Away_team_id : 0),
      Home_team: (matchup.Home_team ? matchup.Home_team : ''),
      Home_team_id: (matchup.Home_team_id ? matchup.Home_team_id : 0),
      Away_team_score: (matchup.Away_team_score ? matchup.Away_team_score : 0),
      Home_team_score: (matchup.Home_team_score ? matchup.Home_team_score : 0)
    },
    validationSchema: Yup.object({
      Season_type: Yup.string()
        .required('Season Type Required'),
      Season_year: Yup.number()
        .integer('Must be an integer.')
        .required('Required'),
      Week: Yup.number()
        .integer('Must be an integer')
        .required('Required'),
      Date: Yup.string()
        .required('Required'),
      Timezone: Yup.string()
        .max(10, 'Must be 10 characters or less')
        .required('Required'),
      Start_time: Yup.string(),
      Away_team: Yup.string()
        .required('Required'),
      Home_team: Yup.string()
        .max(20, 'Max 20 digits')
        .required('Required'),
      Away_team_score: Yup.number()
        .max(99, 'Score too high!'),
      Home_team_score: Yup.number()
        .max(99, 'Score too high!')
    }),
    onSubmit: values => {
      props.handleMatchup(values)
      //formik.resetForm()
      ////setIsBlocking(false) used from Prompt see top about Prompt
    },
    validateOnChange: false,
    validateOnBlur: false
  })

  const teamId = []
  const teamOptions = []

  props.teamNames.forEach(team => {
    const t = {...team}
    teamOptions.push({ value: t.Team_name, label: t.Team_name})
    teamId.push({ teamID: t.Team_id, teamName: t.Team_name })
  })

  const handleChangeSeasonType = (value) => {
    console.log('in handleChangeSeasontype: ', value.value)
    formik.setFieldValue('Season_type', value.value)
  }

  const handleChangeSeasonYear = (value) => {
    formik.setFieldValue('Season_year', value.value)
  }
  const handleBlurSeasonYear = (value) => {
    formik.setFieldTouched('Season_year', value.value)
  }
  const handleChangeWeek = (value) => {
    formik.setFieldValue('Week', value.value)
  }
  const handleBlurWeek = (value) => {
    formik.setFieldTouched('Week', value.value)
  }
  const handleChangeTimezone = (value) => {
    formik.setFieldValue('Timezone', value.value)
  }
  const handleBlurTimezone = (value) => {
    formik.setFieldTouched('Timezone', value.value)
  }
  const handleChangeAwayTeamName = (value) => {
    formik.setFieldValue('Away_team', value.value)
    let foundIndex = -1
    for (let i = 0; i < teamId.length; i++) {
      if (teamId[i].teamName === value.value) {
        foundIndex = i
        break
      }
    }
    formik.setFieldValue('Away_team_id', teamId[foundIndex].teamID)
  }
  const handleBlurAwayTeamName = (value) => {
    formik.setFieldTouched('Away_team', value.value)
  }

  const handleChangeHomeTeamName = (value) => {
    formik.setFieldValue('Home_team', value.value)
    let foundIndex = -1
    for (let i = 0; i < teamId.length; i++) {
      if (teamId[i].teamName === value.value) {
        foundIndex = i
        break
      }
    }
    formik.setFieldValue('Home_team_id', teamId[foundIndex].teamID)
    console.log(teamId[foundIndex].teamID)
  }

  const handleBlurHomeTeamName = (value) => {
    formik.setFieldTouched('Home_team', value.value)
  }

  useEffect(() => {
    if (formik.values.Away_team !== '' && formik.values.Home_team !== '') {
      if (formik.values.Away_team === formik.values.Home_team) {
        setSameTeamError('same teams not allowed')
      } else {
        setSameTeamError('')
      }
    }
  }, [formik.values.Away_team, formik.values.Home_team])

  useEffect(() => {
    if (formik.values.Date !== '' && formik.values.Season_year !== 0) {
      const parseYear = parseInt(formik.values.Date)
      if (parseYear !== formik.values.Season_year) {
        setYearMatchError('matchup date year does not match season year')
      } else {
        setYearMatchError('')
      }
    }
  }, [formik.values.Date, formik.values.Season_year])

  return (
    <>
      {/*<Prompt
        when={isBlocking}
        message={location => `Are you sure you want to go to ${location.pathname}`}
      /> */}
      {props.status && props.postError === null && (
        <AlertDismissible message='matchup successfully added' variant='success' />
      )}
      {props.postError && (
        <AlertDismissible message={props.postError} variant='warning' />
      )}
      {props.rawStatus === 'pending' && (
        <div className='d-flex align-items-center'>
          <strong>Submitting Matchup...</strong>
          <div className='spinner-border ms-auto' role='status' aria-hidden='true' />
        </div>
      )}
      <Card bg='light' className='mt-3'>
        <Card.Header className='d-flex justify-content-between'>
          {props.source} Schedule Matchup
          <Button variant='primary' size='sm' className='ms-3' onClick={() => props.onBackClick()}>Back</Button>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <Form.Group controlId='seasonType'>
                  <Form.Label>Season Type</Form.Label>
                  <Select
                    name='Season_type'
                    onChange={handleChangeSeasonType}
                    onBlur={() => formik.setFieldTouched('Status')}
                    value={value}
                    options={seasonTypeOptions}
                    placeholder={formik.values.Season_type ? formik.values.Season_type :'Select season Type'}
                  />
                  {formik.touched.Season_type && formik.errors.Season_type
                    ? (<Alert variant='warning'>{formik.errors.Season_type}</Alert>)
                    : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='seasonYear'>
                  <Form.Label>Season Year</Form.Label>
                  <Select
                    name='Season_year'
                    onChange={handleChangeSeasonYear}
                    onBlur={handleBlurSeasonYear}
                    value={value}
                    options={seasonYearOptions}
                    placeholder={formik.values.Season_year ? formik.values.Season_year : 'Select season year'}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Group controlId='seasonWeek'>
                  <Form.Label>Week</Form.Label>
                  <Select
                    name='Week'
                    onChange={handleChangeWeek}
                    onBlur={handleBlurWeek}
                    value={value}
                    options={weekOptions}
                    placeholder={formik.values.Week ? formik.values.Week : 'select week'}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='date'>
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    name='Date'
                    type='Date'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Date}
                    placeholder={formik.values.Date ? formik.values.Date : 'Select Date'}
                  />
                  {yearMatchError !== '' &&
                  (<Alert variant='warning'>{yearMatchError}</Alert>)}
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Group controlId='timeZone'>
                  <Form.Label>Timezone</Form.Label>
                  <Select
                    name='Timezone'
                    onChange={handleChangeTimezone}
                    onBlur={handleBlurTimezone}
                    value={value}
                    options={timezoneOptions}
                    placeholder={formik.values.Timezone ? formik.values.Timezone : 'select timezone'}
                  />
                  {formik.touched.Timezone && formik.errors.Timezone
                    ? (<Alert variant='warning'>{formik.errors.Timezone}</Alert>)
                    : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='startTime'>
                  <Form.Label>Start Time</Form.Label>
                  {<Form.Control
                    name='Start_time'
                    type='Start_time'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Start_time}
                    placeholder='Enter start time'
                  />}
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Group controlId='awayTeam'>
                  <Form.Label>Away Team</Form.Label>
                  <Select
                    name='Away_team'
                    onChange={handleChangeAwayTeamName}
                    onBlur={handleBlurAwayTeamName}
                    defaultValue={formik.values.Away_team ? 
                      {label: formik.values.Away_team, value: formik.values.Away_team}: 'Select Away Team'}
                    value={value}
                    options={teamOptions}
                    placeholder={'Select Away Team'}
                  />
                  {formik.touched.Away_team && formik.errors.Away_team
                    ? (<Alert variant='warning'>{formik.errors.Away_team}</Alert>)
                    : null}
                  {sameTeamError !== '' &&
                    (<Alert variant='warning'>{sameTeamError}</Alert>)}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='homeTeam'>
                  <Form.Label>Home Team</Form.Label>
                  <Select
                    name='Home_team'
                    onChange={handleChangeHomeTeamName}
                    onBlur={handleBlurHomeTeamName}
                    defaultValue={formik.values.Home_team ? {label: formik.values.Home_team, value: formik.values.Home_team} : 'Select home team'}
                    value={value}
                    options={teamOptions}
                    placeholder='Select home team'
                  />
                  {formik.touched.Home_team && formik.errors.Home_team
                    ? (<Alert variant='warning'>{formik.errors.Home_team}</Alert>)
                    : null}
                  {sameTeamError !== '' &&
                    (<Alert variant='warning'>{sameTeamError}</Alert>)}
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Group controlId='awayTeamScore'>
                  <Form.Label>Away Team Score</Form.Label>
                  <Form.Control
                    name='Away_team_score'
                    type='number'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Away_team_score}
                    placeholder='Enter away team score'
                  />
                  {formik.touched.Away_team_score && formik.errors.Away_team_score
                    ? (<Alert variant='warning'>{formik.errors.Away_team_score}</Alert>)
                    : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='homeTeamScore'>
                  <Form.Label>Home Team Score</Form.Label>
                  <Form.Control
                    name='Home_team_score'
                    type='number'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Home_team_score}
                    placeholder='Enter home team score'
                  />
                  {formik.touched.Home_team_score && formik.errors.Home_team_score
                    ? (<Alert variant='warning'>{formik.errors.Home_team_score}</Alert>)
                    : null}
                </Form.Group>
              </Col>
            </Row>
            <Row className='justify-content-md-center'>
              <Col lg='3' className='text-center'>
                <Button 
                  active='true' 
                  type='submit' 
                  className='mt-3 btn-primary' 
                  onClick={() => setValue(null)}>{props.source === 'New' ? 'Add' : 'Edit'} Matchup
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )

}

export default ScheduleForm